// config
import { FIREBASE_API } from "./config-global";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

// Initialize Firebase and Firestore
const app = initializeApp(FIREBASE_API);

const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
