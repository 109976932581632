import {
  Button,
  CircularProgress,
  Fab,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { RHFTextField } from "src/components/hook-form";
import FormProvider from "src/components/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { useParams } from "react-router";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { GetfullSizeCDNImageURL } from "src/utils/cloudinaryImageUrl";
import { useWatch } from "react-hook-form";
import { useSelector } from "src/redux/store";
import AfterShareScreen from "./AfterShareScreen";
import FormatChoice from "./FormatChoice";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog";
import { isIOS } from "react-device-detect";
import DownloadDialog from "./DownloadDialog";
import useCopyToClipboard from "src/hooks/useCopyToClipboard";
import Iconify from "../../../components/iconify";
import { httpsCallable } from "firebase/functions";
import { LoadingButton } from "@mui/lab";
import functions from "src/utils/firebaseFunction";

const styles = {
  textField: {
    root: {
      borderRadius: 0,
    },
    input: {
      height: "30px",
      padding: "10px 15px",
      background: "#fff",
      color: "#000",
      borderRadius: "10px",
    },
  },
};

const buildFilename = () => {
  const baseFilename = "MEIN_TIPP";
  const date = new Date().toLocaleDateString();
  return `${baseFilename}_${date}.png`;
};

interface Props {
  shareOnly?: boolean;
  adWhileShareScreen?: string;
  closeShareScreen: any;
}

function ContactDetails({
  shareOnly,
  adWhileShareScreen,
  closeShareScreen,
}: Props) {
  const { sharedImage } = useSelector((state) => state.fanpage);
  const { campaign } = useSelector((state) => state.campaigns);
  const { tip } = useSelector((state) => state.fanpage);
  const defaultValues = JSON.parse(
    localStorage.getItem("participant") || "{}"
  ) || {
    email: "",
    name: "",
  };

  const data: any = campaign;
  const ContactDetailSchema = Yup.object().shape({
    name: Yup.string().required("Dein Name wird benötigt"),
    email: Yup.string()
      .email("Bitte gib eine gültige E-Mail-Adresse ein")
      .required("Bitte gib eine gültige E-Mail-Adresse ein"),
  });
  const methods = useForm({
    resolver: yupResolver(ContactDetailSchema),
    defaultValues,
  });

  const { control } = methods;
  const emailWatch: string = useWatch({
    name: "email",
    control,
  });
  const nameWatch: string = useWatch({
    name: "name",
    control,
  });

  const refShareImg = useRef(null);
  const { id } = useParams();
  const domain = window.location.origin;

  const onShare = async (needDownload: boolean = false) => {
    // const image = refShareImg.current as any;
    if (sharedImage)
      await fetch(sharedImage)
        .then(function (response) {
          return response.blob();
        })
        .then(async function (blob) {
          var file = new File([blob], buildFilename(), { type: "image/png" });
          if (isIOS && needDownload) {
            downloadApi(file);
          } else {
            shareApi(file);
          }
        });
  };

  const [showAd, setShowAd] = useState(false);
  const [showAfterShareAd, setShowAfterShareAd] = useState(false);
  const [isFormatSeleted, setIsFormatSeleted] = useState(false);

  const { copy } = useCopyToClipboard();
  // connectFunctionsEmulator(functions, "localhost", 5001); // for the test on local only
  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );

  const onFormatSelect = () => {
    if (isIOS) {
      copyAndShare();
    } else {
      setIsFormatSeleted(true);
      onShare();
    }
    saveDistributedCounter({
      campaignId: id,
      type: "shares",
    });
  };

  const shareApi = async (file: File) => {
    if (navigator.share) {
      setShowAd(true);

      let shareData = {
        title: "Die Recken Tippspiel",
        text: "Jetzt mitmachen!",
        url: `${domain}/s/${id}`,
        files: [file],
      };
      // when we share on ios, we can only provide an image but not anything else. The reason is that
      // ios either takes text or an image and we put the focus on the image. To support the text, we
      // copy it to the clipboard
      if (isIOS) {
        // TODO: Create type, where title, text and url as optional
        // @ts-ignore
        shareData = {
          files: [file],
        };
      }

      await navigator
        .share(shareData)
        .then(() => {
          setShowAd(false);
          setShowAfterShareAd(true);
        })
        .finally(() => {
          setShowAd(false);
          setShowAfterShareAd(true);
        })
        .catch((error) => {
          console.error("share error", error);
        });
    } else {
      downloadApi(file);
    }
  };

  const downloadApi = (file: File) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    setTimeout(() => {
      setShowAd(false);
      setShowAfterShareAd(true);
    }, 2500);
  };

  const copyAndShare = () => {
    copy(data.text + "\n" + `${window.location.href}/dierecken`);
    setShowDownloadDialog(true);
  };

  const [issubmitted, setIssubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values: any) => {
    // const functions = getFunctions(getApp());
    setIsLoading(true);
    const saveParticipants = httpsCallable(functions, "saveParticipants");
    const dataToStorage = {
      email: values.email.toLowerCase(),
      name: values.name,
    };
    localStorage.setItem("participant", JSON.stringify(dataToStorage));

    saveParticipants({
      email: values.email.toLowerCase(),
      name: values.name,
      campaignId: id,
      tip: `${tip.team1}:${tip.team2}`,
    })
      .then((result) => {
        setIsLoading(false);
        setIssubmitted(true);
        saveDistributedCounter({
          campaignId: id,
          type: "registrations",
        });
        saveDistributedCounter({
          campaignId: id,
          type: "contact_page",
        });
      })
      .catch((error) => {
        console.error("firebase funtion error:", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (shareOnly) {
      setIssubmitted(true);
    }
    return () => {
      setIssubmitted(false);
    };
  }, [shareOnly]);

  const [showDialog, setShowDialog] = useState(false);
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const OpenDialog = () => {
    setShowDialog(true);
  };

  const CloseDialog = () => {
    setShowDialog(false);
  };

  const closeDownloadDialog = () => {
    setShowDownloadDialog(false);
  };

  const [isDownloaded, setIsDownloaded] = useState(false);
  const handleDownload = () => {
    onShare(isIOS);
    setTimeout(() => {
      setIsDownloaded(true);
    }, 1500);
  };

  const handleShare = () => {
    closeDownloadDialog();
    setIsFormatSeleted(true);
    onShare();
  };

  return (
    <Fragment>
      {!issubmitted && (
        <Stack
          sx={{ mt: 3 }}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="calc(100% - 40px)"
        >
          <Stack
            width={"100%"}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{
              px: 1,
            }}
          >
            <Stack direction={"row"} justifyContent={"center"} flexGrow={1}>
              <Typography
                sx={{
                  textAlign: "center",
                }}
              >
                {!shareOnly && `Kontaktdaten für Gewinnbenachrichtigung`}
              </Typography>
            </Stack>
            <div>
              <Stack>
                <Fab
                  size="small"
                  variant="outlined"
                  color="default"
                  aria-label="close"
                  onClick={closeShareScreen}
                >
                  <Iconify icon="ion:close-outline" color={"#fff"} />
                </Fab>
              </Stack>
            </div>
          </Stack>

          <Stack
            sx={{
              px: 3,
              height: `50%`,
              overflow: `hidden`,
              img: {
                objectFit: "contain",
                height: `100%`,
              },
            }}
          >
            <img
              ref={refShareImg}
              src={sharedImage || "/assets/images/campaign/share.jpg"}
              alt=""
            />
          </Stack>

          <Stack
            sx={{
              padding: "16px",
              width: "100%",
              form: {
                width: "100%",
              },
            }}
          >
            {!shareOnly && (
              <FormProvider
                methods={methods}
                onSubmit={methods.handleSubmit(onSubmit)}
              >
                <Stack width="100%" spacing={2}>
                  <RHFTextField
                    sx={styles.textField}
                    name="name"
                    placeholder="Name"
                  />
                  <RHFTextField
                    sx={styles.textField}
                    name="email"
                    placeholder="E-Mail"
                  />

                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: "center",
                      b: {
                        color: "#5393C0",
                      },
                      span: {
                        width: "75%",
                        display: "block",
                        margin: "0 auto",
                      },
                    }}
                  >
                    <span onClick={OpenDialog}>
                      Mit dem Klicken auf <strong>Jetzt teilnehmen</strong>{" "}
                      stimme ich den
                      <b> Teilnahme- und Datenschutzbedingungen</b> zu.
                    </span>
                  </Typography>
                  <LoadingButton
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    type="submit"
                    disabled={emailWatch && nameWatch ? false : true}
                    loading={isLoading}
                  >
                    Jetzt teilnehmen
                  </LoadingButton>
                </Stack>
              </FormProvider>
            )}
          </Stack>
        </Stack>
      )}

      {issubmitted && !isFormatSeleted && (
        <FormatChoice onFormatSelect={onFormatSelect} />
      )}

      {issubmitted && isFormatSeleted && (
        <Fragment>
          {showAd && adWhileShareScreen && <ShowAd ad={adWhileShareScreen} />}
          {showAfterShareAd && <AfterShareScreen />}
        </Fragment>
      )}

      {isIOS && (
        <DownloadDialog
          open={showDownloadDialog}
          handleClose={closeDownloadDialog}
          handleDownload={handleDownload}
          handleShare={handleShare}
          isDownloaded={isDownloaded}
          textToCopy={data.text + "\n" + `${window.location.href}/dierecken`}
        />
      )}

      <PrivacyPolicyDialog open={showDialog} handleClose={CloseDialog} />
    </Fragment>
  );
}

export default ContactDetails;

const forAdShowTime = 3000; //in milliseconds

const ShowAd = ({ ad }: { ad: string }) => {
  const [hide, setHide] = useState(false);
  const { id } = useParams();
  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      // setHide(true);
    }, forAdShowTime);
    saveDistributedCounter({
      campaignId: id,
      type: "ad_screen_2",
    });
    return () => clearTimeout(timer);
  }, []);
  return (
    <Stack
      position="absolute"
      zIndex="99999"
      height="100%"
      width="100%"
      sx={{
        display: hide ? "none" : "block",

        img: {
          height: "100%",
          width: "100%",
        },
      }}
    >
      <img src={GetfullSizeCDNImageURL(ad)} alt="show ad" />
    </Stack>
  );
};
