import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { getFirestore } from "redux-firestore";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import SpaceReducer from "./slices/space";
import SpacesReducer from "./slices/spaces";
import CampaignsReducer from "./slices/campaigns";
import FanpageReducer from "./slices/fan";
import FindCampaignBySlug from "./slices/findsCampaignBySlug";
// ----------------------------------------------------------------------

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

export const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  space: SpaceReducer,
  spaces: SpacesReducer,
  campaigns: CampaignsReducer,
  fanpage: FanpageReducer,
  product: persistReducer(productPersistConfig, productReducer),
  findCampaignBySlug: FindCampaignBySlug,
});

export default rootReducer;
