import {
  Alert,
  Button,
  Card,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import BeforeShareScreen from "./share/BeforeShareScreen";
import FormProvider from "src/components/hook-form/FormProvider";
import { IFanResponse } from "src/@types/fan";
import { getPreviewImageUrl } from "src/utils/cloudinaryImageUrl";
import { set_tip_for_image } from "src/redux/slices/fan";
import { dispatch, useSelector } from "src/redux/store";
import TypographyWithFont from "./TypographyWithFont";
import { useNavigate, useParams } from "react-router";
import { get_campaign_byid } from "src/redux/slices/campaigns";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import Header from "./Header";
import { preloadMediaAssets } from "../../utils/preloadMediaAssets";

const styles = {
  textField: {
    background: "#fff",
    color: "#000",
    input: {
      width: "80px",
      height: "80px",
      padding: "0 5px",
      fontSize: "42px",
      textAlign: `center`,
    },
    borderRadius: "10px",
  },
};

function ActiveCampaign() {
  const [shareOnly, setShareOnly] = useState(false);
  const [shareScreenView, setShareScreenView] = useState(false);
  const methods = useForm();
  const HEIGHT = parseInt(window.innerHeight.toString());
  const { id } = useParams();
  const { campaign: data } = useSelector((state) => state.campaigns);
  const campaign = data as any;
  const [isLoading, setisLoading] = useState(true);
  const navigate = useNavigate();

  const [buttonIsEnabled, setButtonIsEnabled] = useState(false);

  useEffect(() => {
    if (id) {
      if (!campaign) {
        dispatch(get_campaign_byid(id));
      } else {
        setisLoading(false);
      }
    }
    return () => {};
  }, [id]);

  useEffect(() => {
    if (campaign && isLoading) {
      setisLoading(false);
    }
    return () => {};
  }, [isLoading, campaign]);

  const sharetoNextToScreen = () => {
    if (!userInputIsValid()) return;
    setShareOnly(false);
    setShareScreenView(true);
  };

  const justSharetoNextToScreen = () => {
    if (!userInputIsValid()) return;
    setShareOnly(true);
    setShareScreenView(true);
  };

  const goToRegisterPage = () => {
    if (!userInputIsValid()) return;
    navigate(`/s/${id}/register`);
  };

  /*
   * Returns true if the user's input is valid and the user can continue. If not, the user will also be prompted with
   * a warning
   * */
  const userInputIsValid = () => {
    if (isdisabled) {
      setShowInputWarning(true);
      return false;
    }
    return true;
  };

  const handleSnackbarClose = () => {
    setShowInputWarning(false);
  };

  const closeShareScreen = () => {
    setShareScreenView(false);
  };

  const onSubmit = () => {};

  const [localTip, setLocalTip] = useState({
    team1: 0,
    team2: 0,
  });

  const onTextFieldChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    console.log(name, value);

    if (value.length <= 3) {
      setLocalTip((old) => {
        const obj = {
          ...old,
          [name]: parseInt(value.trim()),
        };
        dispatch(set_tip_for_image(obj));
        return obj;
      });
    }
  };

  const handleKeyDown = (e: any) => {
    const keyIsEnter = e.keyCode === 13;
    console.log(e);
    if (keyIsEnter) {
      e.target.blur();
    }
  };

  const [isdisabled, setIsdisabled] = useState(true);
  const [showInputWarning, setShowInputWarning] = useState(false);
  useEffect(() => {
    if (
      localTip.team1 <= 0 ||
      localTip.team2 <= 0 ||
      isNaN(localTip.team1) ||
      isNaN(localTip.team2)
    ) {
      setIsdisabled(true);
    } else {
      setIsdisabled(false);
    }
    return () => {};
  }, [localTip]);

  const [adConfig, setAdConfig] = useState({
    campaign_ad_1: "",
    campaign_ad_2: "",
    campaign_ad_3: "",
    campaign_ad_4: "",
    campaign_ad_5: "",
    campaign_ad_6: "",
    campaign_ad_7: "",
    campaign_ad_8: "",
  });

  const [content, setContent] = useState({
    campaign_content_1: "",
    campaign_content_2: "",
    campaign_content_3: "",
    campaign_content_4: "",
  });

  const [contentColor, setContentColor] = useState({
    campaign_content_1: "",
    campaign_content_2: "",
    campaign_content_3: "",
    campaign_content_4: "",
  });
  const [contentFont, setContentFont] = useState({
    campaign_content_1: "",
    campaign_content_2: "",
    campaign_content_3: "",
    campaign_content_4: "",
  });
  const [isContentImage, setIsContentImage] = useState({
    campaign_content_1: false,
    campaign_content_2: false,
    campaign_content_3: false,
    campaign_content_4: false,
  });

  useEffect(() => {
    if (campaign) {
      campaign?.ad_config.forEach((element: any) => {
        setAdConfig((old) => ({
          ...old,
          [element.dataforId]: element.image,
        }));
      });

      campaign?.config_section.forEach((element: any) => {
        setIsContentImage((old) => ({
          ...old,
          [element.dataforId]:
            (element.image && true) || (element.text && false),
        }));
        setContentColor((old) => ({
          ...old,
          [element.dataforId]: element.color,
        }));
        setContentFont((old) => ({
          ...old,
          [element.dataforId]: element.font?.value,
        }));
        setContent((old) => ({
          ...old,
          [element.dataforId]: element.image || element.text,
        }));
      });
    }
    return () => {};
  }, [campaign]);

  return (
    <>
      <Snackbar
        sx={{ mt: 10 }}
        open={showInputWarning}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleSnackbarClose}
        autoHideDuration={1500}
      >
        <Alert
          severity="success"
          sx={{ width: "100%" }}
          onClose={handleSnackbarClose}
        >
          Ein Tipp wird benötigt um fortzufahren!
        </Alert>
      </Snackbar>
      {/* {isLoading && <LoadingScreen />} */}
      <Card
        id="ActiveCampaignCard"
        sx={{
          padding: `0px`,
          backgroundImage: `url(${campaign?.media_asset_image_story_format})`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `auto 100vh`,
          width: `100%`,
          height: `100%`,
          color: "#fff",
          borderRadius: 0,
          backgroundPosition: "center",
          overflowY: "scroll",
        }}
      >
        <Fragment>
          <Header />
          {!shareScreenView && (
            <Stack
              id="ActiveCampaign"
              justifyContent="space-around"
              alignItems="center"
              width="100%"
              height="calc(100% - 20px)"
              overflow={"auto"}
              sx={{
                marginTop: "20px",
                userSelect: "none",
              }}
            >
              <Stack
                justifyContent="space-between"
                alignItems="center"
                width="75%"
              >
                <TypographyWithFont
                  id="campaign_content_1"
                  variant="h1"
                  fontPath={contentFont.campaign_content_1}
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "13vw",
                    color: contentColor.campaign_content_1,
                    whiteSpace: "nowrap",
                  }}
                >
                  {content.campaign_content_1}
                </TypographyWithFont>

                <TypographyWithFont
                  id="campaign_content_2"
                  variant="h1"
                  fontPath={contentFont.campaign_content_2}
                  sx={{
                    textTransform: "uppercase",
                    fontSize: "13vw",
                    borderTop: "1px solid #fff",
                    color: contentColor.campaign_content_2,
                  }}
                >
                  {content.campaign_content_2}
                </TypographyWithFont>

                {adConfig.campaign_ad_4 && (
                  <Stack alignItems="center">
                    <Typography variant="body2">präsentiert von:</Typography>
                    <img
                      src={getPreviewImageUrl(adConfig.campaign_ad_4, 100, 100)}
                      alt=""
                    />
                  </Stack>
                )}

                <Typography
                  variant="h3"
                  sx={{
                    pt: 7,
                    textTransform: "uppercase",
                  }}
                >
                  HIER TIPP EINGEBEN:
                </Typography>

                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                  width="100%"
                  sx={{
                    py: 2,
                    img: {
                      height: "100px",
                      width: "100px",
                    },
                  }}
                >
                  {isContentImage.campaign_content_3 ? (
                    <img
                      src={getPreviewImageUrl(content.campaign_content_3)}
                      alt=""
                    />
                  ) : (
                    <Typography variant="h3">
                      {content.campaign_content_3}
                    </Typography>
                  )}

                  <Typography variant="h1"> VS </Typography>

                  {isContentImage.campaign_content_4 ? (
                    <img
                      src={getPreviewImageUrl(content.campaign_content_4)}
                      alt=""
                    />
                  ) : (
                    <Typography variant="h3">
                      {content.campaign_content_4}
                    </Typography>
                  )}
                </Stack>

                <Stack
                  alignItems="center"
                  justifyContent="space-between"
                  direction="row"
                  sx={{
                    mt: 1,
                    width: "100%",
                    form: {
                      width: "100%",
                    },
                  }}
                >
                  <FormProvider
                    methods={methods}
                    onSubmit={methods.handleSubmit(onSubmit)}
                  >
                    <Stack
                      alignItems="center"
                      justifyContent="space-between"
                      direction="row"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Typography variant="h3">
                        <TextField
                          sx={styles.textField}
                          name="team1"
                          variant="filled"
                          value={localTip.team1 === 0 ? null : localTip.team1}
                          type="number"
                          onChange={onTextFieldChange}
                        />
                      </Typography>
                      <Typography variant="h2"> : </Typography>
                      <Typography variant="h3">
                        <TextField
                          sx={styles.textField}
                          name="team2"
                          variant="filled"
                          value={localTip.team2 === 0 ? null : localTip.team2}
                          type="number"
                          onChange={onTextFieldChange}
                          onKeyDown={handleKeyDown}
                        />
                      </Typography>
                    </Stack>
                  </FormProvider>
                </Stack>
              </Stack>

              <Stack id="ActiveCampaignButton" width="80%" marginBottom="10px">
                {/* <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  onClick={sharetoNextToScreen}
                >
                  Am Gewinnspiel teilnehmen
                </Button>
                <Button
                  sx={{ mt: 2, textTransform: "none" }}
                  color="inherit"
                  variant="text"
                  onClick={justSharetoNextToScreen}
                >
                  Nur teilen
                </Button> */}
                <Button
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  onClick={goToRegisterPage}
                  disabled={isdisabled}
                >
                  Weiter
                </Button>
              </Stack>
            </Stack>
          )}

          {shareScreenView && (
            <BeforeShareScreen
              shareOnly={shareOnly}
              data={campaign}
              closeShareScreen={closeShareScreen}
              adBeforeSharing={adConfig.campaign_ad_1}
              adWhileShareScreen={adConfig.campaign_ad_2}
            />
          )}
        </Fragment>
      </Card>
    </>
  );
}

export default ActiveCampaign;
