import keyBy from "lodash/keyBy";
import { createSlice, Dispatch } from "@reduxjs/toolkit";

import { db } from "../../firebase";
import {
  collection,
  addDoc,
  Timestamp,
  query,
  orderBy,
  onSnapshot,
} from "firebase/firestore";

// utils
import axios from "../../utils/axios";
// @types
import { ISpace, ISpaceState } from "../../@types/space";

// ----------------------------------------------------------------------

const initialState: ISpaceState = {
  isLoading: false,
  error: null,
  spaces: [],
  space: null,
};

const slice = createSlice({
  name: "space",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET SPACES
    getSpaceSuccess(state, action) {
      state.isLoading = false;
      state.spaces = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getSpaces() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const q = query(collection(db, "space"));
      onSnapshot(q, (querySnapshot) => {
        console.log(
          querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }))
        );
        dispatch(
          slice.actions.getSpaceSuccess(
            querySnapshot.docs.map((doc) => ({
              id: doc.id,
              data: doc.data(),
            }))
          )
        );
      });
      //const response = await axios.get('/api/mail/labels');
      //dispatch(slice.actions.getProductsSuccess(response.data.labels));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
