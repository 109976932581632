import { Card, Stack } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import Header from "src/layouts/compact/Header";
import { dispatch, useSelector } from "src/redux/store";
import Footer from "./Footer";
import PreviewCampaign from "./PreviewCampaign";
import QRCard from "./QRCard";
import {
  getPreviewImageUrl,
  getSpaceMobileHeaderImageUrl,
} from "../../../utils/cloudinaryImageUrl";
import { get_space_byid } from "../../../redux/slices/spaces";
import { httpsCallable } from "firebase/functions";
import { useNavigate, useParams } from "react-router";
import functions from "src/utils/firebaseFunction";

function SharedDesktopScreen() {
  const { spacebyid } = useSelector((state) => state.spaces);
  const Space = spacebyid as any;
  const { id } = useParams();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [campaign, setCampaign] = useState<any>();
  const [isLoading, setIsLoading] = useState(true);
  // connectFunctionsEmulator(functions, "localhost", 5001);
  const getCampaignDetailById = httpsCallable(
    functions,
    "getCampaignDetailById"
  );

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      getCampaignDetailById({ campaignId: id })
        .then((res: any) => {
          if (res && res.data) {
            const {
              campaignData,
              isPlanned: campaignPlanned,
              isActive: campaignActive,
            } = res.data;
            setCampaign(campaignData);
            setIsActive(campaignActive);
            if (!campaignActive && !campaignPlanned) {
              navigate("not-active");
            }
          }
          setIsLoading(false);
        })
        .catch((_e) => {
          setIsLoading(false);
        });
    }
    return () => {};
  }, [id]);

  useEffect(() => {
    if (campaign) {
      if (campaign?.space_id) {
        dispatch(get_space_byid(campaign.space_id));
      }
    }
    return () => {};
  }, [campaign]);

  return (
    <Fragment>
      <Header isOffset />
      {isLoading && <LoadingScreen />}
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100vh" }}
      >
        <Card
          sx={{
            overflow: "hidden",
            boxShadow: 3,
          }}
        >
          <Stack
            justifyContent="center"
            alignItems="center"
            direction={"column"}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              direction={"row"}
            >
              <div>
                {Space?.logo && (
                  <img height="100px" src={getPreviewImageUrl(Space.logo)} />
                )}
              </div>
            </Stack>

            <Stack
              justifyContent="center"
              alignItems="center"
              direction={"row"}
            >
              {isActive && <PreviewCampaign data={campaign as any} />}
              <QRCard />
            </Stack>
          </Stack>
        </Card>
      </Stack>
      <Footer />
    </Fragment>
  );
}

export default SharedDesktopScreen;
