export type IAutocompleteOptionType = {
  value: string;
  label: string;
};

export interface IConfigType {
  id: string;
  title: string;
  required: boolean;
  description: string;
  verify_aspect_ratio: boolean;
  required_min_aspect_ratio: number;
  required_max_aspect_ratio: number;
  min_width_in_px: number;
  max_width_in_px: number;
  text_enabled: boolean;
  image_enabled: boolean;
  video_enabled: boolean;
  target_field: string;
  allowed_image_file_types: string[];
  allowed_video_file_types: string[];
  use_as_background: boolean;
  check_sponsorship?: boolean;
  optimal_for_platform: string[];
}

export interface ITextConfigType {
  id: string;
  title: string;
  required: boolean;
  check_sponsorship: boolean;
  description: string;
  text: string | null;
  text_max_chars: number | null;
  text_max_lines: number | null;
  verify_aspect_ratio: boolean;
  required_min_aspect_ratio: number;
  required_max_aspect_ratio: number;
  min_width_in_px: number;
  max_width_in_px: number;
  text_enabled: boolean;
  image_enabled: boolean;
  video_enabled: boolean;
  target_media_image_upload: string;
  target_media_video_upload: string;
  target_font: string;
  target_text: string;
  help_text_image_url: string | null;
  help_image_image_url: string | null;
  help_video_image_url: string | null;
  allowed_image_file_types: string[];
  allowed_video_file_types: string[];
  allow_cta: boolean;
  cta_field: string;
}

export interface IConfigProps {
  configs: IConfigType[];
}

export interface ITextConfigProps {
  configs: ITextConfigType[];
}

export interface ICampaignsCofigType {
  title: string;
  identifier: string;
  short_description: string;
  description: string;
  lottery_enabled: boolean;
  field_config: {
    media_assets: IConfigType[];
    text_config: ITextConfigType[];
    ad_config: ITextConfigType[];
  };
}

export type ICampaignsType = {
  id?: string;
  adAfter: string;
  adBefore: string;
  adWhile: string;
  analytics_count_publications: string;
  description: string;
  enableLottery: string;
  end: string;
  image: string;
  infoAboutText: string;
  name: string;
  portrait: string;
  privacyPolicyUrl: string;
  publication_status: string;
  publishNow: boolean;
  publish_end_date: string;
  publish_now: string;
  publish_start_date: string;
  squareFormat: string;
  start: string;
  storyFormat: string;
  termsOfService: string;
  title_0: string;
  title_1: string;
  title_2: string;
  title_3: string;
  title_4: string;
  title_5: string;
  type: string;

  // analytics_count_publications: string,
  // image: string,
  // name: string,
  // publication_status: boolean,
  // publish_end_date: any,
  // publish_now: boolean,
  // publish_start_date: any,
  // type: string,
};

// this is used to determine the status.
//publication_status == draft -> draft
//publication_status == published && publish_now -> active
//publication_status == published && publish_start_date >= now && publish_end_date <= now -> active
//publication_status == published &&  publish_end_date > now -> finished
//publication_status == published &&  now < publish_start_date -> planned

export interface ICampaignStoreType {
  short_id: string;
  name: string;
  type: string; // possible options and configs are defined mapping campaignTypes
  description: string;
  lottery_enabled: boolean;
  lottery_privacy_url: string | null;
  lotter_tos_url: string | null;
  lottery_expected_num_winners: number;
  lottery_winners: string[];

  publish_now: boolean;
  publish_start_date: string | null;
  publish_end_date: string | null;
  publication_status: string;

  text: "post content text that should be published";

  // these fields should be set dynamically based on the config of the input
  media_asset_image_story_format?: string | null;
  media_asset_image_portrait_format?: string | null;
  media_asset_image_square_format?: string | null;

  config_sections?: {
    image: null;
    file_format: null;
    video: null;
    text: null;
    font: null;
  }[];

  ad_config_section?: {
    image: null;
    file_format: null;
    video: null;
    text: null;
    font: null;
  }[];

  final_cta: null;
  drawingConfig: string;
  space_id: string;
  // they should be handled via firebase extension for distributed counters
  // "analytics_count_views": 0,
  // "analytics_count_publications": 0,
  // "analytics_count_registrations": 0,
  // "analytics_counter_media_format_story": 0,
  // "analytics_counter_media_format_square": 0,
  // "analytics_counter_media_format_portrait": 0,

  // "created": "<auto generated>",
  // "updated": "<auto generated>"
}

export enum enumPublicationStatus {
  ACTIVE = "active",
  DRAFT = "draft",
  PLANNED = "planned",
  FINISHED = "finished",
}

export interface ILotteryParticipantsForCampaignType {
  id?: string;
  name?: string;
  email?: string;
  entered_data?: string;
  updated?: string;
  created?: string;
  newsletter_accepted?: boolean;
  shared_post_step_1?: boolean;
  shared_post_step_2?: boolean;
  checkbox1?: boolean;
}
