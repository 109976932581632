// i18n
import "./locales/i18n";

// scroll bar
import "simplebar/src/simplebar.css";

// lazy image
import "react-lazy-load-image-component/src/effects/blur.css";

// ----------------------------------------------------------------------
// @mui
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
// redux
import { store, persistor } from "./redux/store";
import { Provider as ReduxProvider } from "react-redux";

import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// locales
import ThemeLocalization from "./locales";
// components
import SnackbarProvider from "./components/snackbar";
import ScrollToTop from "./components/scroll-to-top";
import { MotionLazyContainer } from "./components/animate";
import { ThemeSettings, SettingsProvider } from "./components/settings";
// Check our docs
// https://docs.minimals.cc/authentication/ts-version

import { AuthProvider } from "./auth/FirebaseContext";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SettingsProvider>
              <BrowserRouter>
                <ScrollToTop />
                <MotionLazyContainer>
                  <ThemeProvider>
                    {/* <ThemeSettings> */}
                    <ThemeLocalization>
                      <SnackbarProvider>
                        <Router />
                      </SnackbarProvider>
                    </ThemeLocalization>
                    {/* </ThemeSettings> */}
                  </ThemeProvider>
                </MotionLazyContainer>
              </BrowserRouter>
            </SettingsProvider>
          </LocalizationProvider>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}
