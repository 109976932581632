import React, { Fragment } from "react";
import SharedDesktopScreen from "./DesktopScreen/SharedDesktopScreen";
import { Stack, Typography, useMediaQuery } from "@mui/material";
import { isMobile } from "react-device-detect";
import { Outlet } from "react-router";
import Iconify from "src/components/iconify/Iconify";

function CommonClientLayout() {
  const isLandscape = useMediaQuery("(orientation:landscape)");
  return (
    <Fragment>
      {!isMobile ? (
        <SharedDesktopScreen />
      ) : (
        <Fragment>
          <Stack
            direction={"column"}
            sx={{
              height: "100%",
            }}
          >
            {isLandscape ? <LandscapeScreenView /> : <Outlet />}
          </Stack>
        </Fragment>
      )}
    </Fragment>
  );
}

export default CommonClientLayout;

const LandscapeScreenView = () => {
  return (
    <Stack
      display={"flex"}
      direction={"column"}
      justifyContent="center"
      alignItems={"center"}
      sx={{
        height: "100%",
      }}
    >
      <Typography variant="h3">Only works on portrait view.</Typography>
      <Typography variant="h4">
        Rotate your phone.
        <Iconify icon="mdi:rotate-right-variant" height={24} width={24} />
      </Typography>
    </Stack>
  );
};
