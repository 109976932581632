import { LinearProgress, Stack, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { get_campaign_byid } from "src/redux/slices/campaigns";
import { dispatch, useSelector } from "src/redux/store";
import { GetfullSizeCDNImageURL } from "src/utils/cloudinaryImageUrl";
import LoadingScreen from "src/components/loading-screen/LoadingScreen";
import functions from "src/utils/firebaseFunction";
import ReactPlayer from "react-player";
import { isIOS } from "react-device-detect";

function LoadingScreenOne() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    campaign: data,
    adConfing: adConfingData,
    adConfingOneHasVideo,
  } = useSelector((state) => state.campaigns);
  const { sharedImage } = useSelector((state) => state.fanpage);
  const campaign = data as any;
  const adConfig = adConfingData as any;
  const [showShareScreen, setShowShareScreen] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );

  useEffect(() => {
    if (!sharedImage) {
      navigate(`/s/${id}/intro`);
    }
    return () => {};
  }, [sharedImage]);

  useEffect(() => {
    if (id) {
      if (!campaign) {
        setIsLoading(true);
        dispatch(get_campaign_byid(id));
      } else {
        setIsLoading(false);
      }
    }
    return () => {};
  }, [id, campaign]);

  useEffect(() => {
    saveDistributedCounter({
      campaignId: id,
      type: "ad_screen_1",
    });
    return () => {};
  }, []);

  useEffect(() => {
    if (!adConfingOneHasVideo && adConfig.campaign_ad_1) {
      const timer = setInterval(() => {
        if (campaign && !showShareScreen && !adConfingOneHasVideo) {
          setProgress((oldProgress) => {
            if (oldProgress === 100) {
              return 0;
            }
            const diff = Math.random() * 20;
            return Math.min(oldProgress + diff, 100);
          });
        }
      }, 650);
      return () => {
        clearInterval(timer);
      };
    } else {
      setProgress(0);
    }
  }, [campaign, showShareScreen, adConfig]);

  useEffect(() => {
    if (progress === 100 && !adConfingOneHasVideo) {
      setShowShareScreen(true);
      navigate(`/s/${id}/share-screen`);
    }
    return () => {};
  }, [progress]);

  return (
    <Fragment>
      <>
        <Stack
          position="absolute"
          zIndex="99999"
          height="100%"
          width="100%"
          bgcolor={"#000"}
          sx={{
            display: `${showShareScreen ? "none" : "block"}`,
            img: {
              height: "100%",
              width: "100%",
            },
            video: {
              width: "100%",
              height: "100vh !important",
            },
            div: {
              width: "100% !important",
            },
          }}
        >
          {adConfingOneHasVideo ? (
            <>
              <ReactPlayer
                url={adConfig.campaign_ad_1}
                playing={true}
                playsinline={true}
                muted={isIOS ? true : false}
                style={{ width: "100%", height: "100vh" }}
                onReady={(p) => {
                  setProgress(0);
                }}
                onProgress={(s) => {
                  setProgress((s.playedSeconds * 100) / s.loadedSeconds);
                }}
                onEnded={() => {
                  setTimeout(() => {
                    navigate(`/s/${id}/share-screen`);
                  }, 2000);
                }}
              />
            </>
          ) : (
            <img
              src={GetfullSizeCDNImageURL(adConfig.campaign_ad_1)}
              alt="show ad"
            />
          )}
          <Stack
            sx={{
              position: "absolute",
              bottom: "50px",
              left: "0",
              right: "0",
              padding: "0 15px",
            }}
          >
            {/*<<<<<<< HEAD*/}
            {/*            {adConfingOneHasVideo ? (*/}
            {/*              <>*/}
            {/*                <ReactPlayer*/}
            {/*                  url={adConfig.campaign_ad_1}*/}
            {/*                  playing={true}*/}
            {/*                  playsinline={true}*/}
            {/*                  muted={isIOS ? true : false}*/}
            {/*                  style={{ width: "100%", height: "100vh" }}*/}
            {/*                  onReady={(p) => {*/}
            {/*                    setProgress(0);*/}
            {/*                  }}*/}
            {/*                  onProgress={(s) => {*/}
            {/*                    setProgress((s.playedSeconds * 100) / s.loadedSeconds);*/}
            {/*                  }}*/}
            {/*                  onEnded={() => {*/}
            {/*                    setTimeout(() => {*/}
            {/*                      navigate(`/s/${id}/share-screen`);*/}
            {/*                    }, 2000);*/}
            {/*                  }}*/}
            {/*                />*/}
            {/*              </>*/}
            {/*            ) : (*/}
            {/*              <img*/}
            {/*                src={GetfullSizeCDNImageURL(adConfig.campaign_ad_1)}*/}
            {/*                alt="show ad"*/}
            {/*              />*/}
            {/*            )}*/}
            {/*            <Stack*/}
            {/*              sx={{*/}
            {/*                position: "absolute",*/}
            {/*                bottom: "50px",*/}
            {/*                left: "0",*/}
            {/*                right: "0",*/}
            {/*                padding: "0 15px",*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              <Typography color={"#fff"} textAlign={"center"}>*/}
            {/*                Dein Tipp wird gesendet ...*/}
            {/*              </Typography>*/}
            {/*              <LinearProgress*/}
            {/*                sx={{ height: "40px", backgroundColor: "#fff" }}*/}
            {/*                color="success"*/}
            {/*                variant="determinate"*/}
            {/*                value={progress}*/}
            {/*              />*/}
            {/*            </Stack>*/}
            {/*=======*/}
            <Typography color={"#fff"} textAlign={"center"}>
              Dein Tipp wird gesendet ...
            </Typography>
            <LinearProgress
              sx={{ height: "40px", backgroundColor: "#fff" }}
              color="success"
              variant="determinate"
              value={progress}
            />
            {/*>>>>>>> 32713ae5ea5be0c193e17489645785cfa9795b34*/}
          </Stack>
        </Stack>
      </>
    </Fragment>
  );
}

export default LoadingScreenOne;
