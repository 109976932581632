import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { HEADER, NAV } from "src/config-global";
import { useTheme } from "@mui/material/styles";
import { bgBlur } from "src/utils/cssStyles";
import { dispatch, useSelector } from "src/redux/store";
import { useEffect } from "react";
import { get_space_byid } from "src/redux/slices/spaces";
import Iconify from "src/components/iconify/Iconify";
import {
  getSpaceMobileHeaderImageUrl,
  getTablePreviewImageUrl,
} from "src/utils/cloudinaryImageUrl";
import Logo from "../../components/logo";
import NavAccount from "../../layouts/dashboard/nav/NavAccount";
import { NavSectionVertical } from "../../components/nav-section";
import NavDocs from "../../layouts/dashboard/nav/NavDocs";
import navConfig from "../../layouts/fanArea/nav/config-navigation";
import Scrollbar from "../../components/scrollbar";
import NavList from "../../components/nav-section/horizontal/NavList";
import NavMobile from "../../layouts/fanArea/nav/mobile/NavMobile";

function Header() {
  const theme = useTheme();

  const { campaign: Data } = useSelector((state) => state.campaigns);
  const { spacebyid, isLoading } = useSelector((state) => state.spaces);
  const campaign = Data as any;
  const Space = spacebyid as any;
  useEffect(() => {
    if (campaign) {
      if (campaign.space_id) {
        dispatch(get_space_byid(campaign.space_id));
      }
    }
    return () => {};
  }, [campaign]);

  useEffect(() => {
    return () => {};
  }, [spacebyid]);

  return (
    <>
      <AppBar
        color="default"
        sx={{
          position: "relative",
          height: "40px",
          minHeight: "40px",
          boxShadow: 0,
          zIndex: 1,
        }}
      >
        <Toolbar
          sx={{
            height: {
              xs: HEADER.H_MOBILE,
              md: "40px",
            },
            transition: theme.transitions.create(
              ["height", "background-color"],
              {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
              }
            ),
            ...bgBlur({ color: theme.palette.background.default }),
          }}
        >
          <Stack
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            spacing={2}
            width="100%"
          >
            <div>
              {Space?.logo && (
                <img
                  height="40px"
                  src={getSpaceMobileHeaderImageUrl(Space.logo)}
                />
              )}
            </div>
            <Typography variant="h4">{Space?.name}</Typography>
            <NavMobile isOffset={true} data={navConfig} />
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
