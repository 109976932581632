import { CircularProgress, Fab, Stack, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import Iconify from "src/components/iconify/Iconify";
import "yet-another-react-lightbox/styles.css";
import FormatChoice from "./FormatChoice";
import ContactDetails from "./ContactDetails";
import { GetfullSizeCDNImageURL } from "src/utils/cloudinaryImageUrl";
import useCreateSharableImage from "../hook/useCreateSharableImage";
import { useSelector } from "src/redux/store";
import { useParams } from "react-router";
import { httpsCallable } from "firebase/functions";
import functions from "src/utils/firebaseFunction";

interface Props {
  closeShareScreen: () => void;
  data: any;
  shareOnly?: boolean;
  adBeforeSharing?: string;
  adWhileShareScreen?: string;
}

function BeforeShareScreen({
  closeShareScreen,
  shareOnly,
  adBeforeSharing,
  adWhileShareScreen,
  data,
}: Props) {
  const { image } = useCreateSharableImage(data);

  return (
    <Fragment>
      <Stack
        id="BeforeShareScreen"
        position="absolute"
        top="0"
        left="0"
        bgcolor={"#282929"}
        width="100%"
        height="100%"
        alignItems="center"
      >
        {/*<Stack position="absolute" top="20px" right="10px">*/}
        {/*  <Fab*/}
        {/*    size="small"*/}
        {/*    variant="outlined"*/}
        {/*    color="default"*/}
        {/*    aria-label="close"*/}
        {/*    onClick={closeShareScreen}*/}
        {/*  >*/}
        {/*    <Iconify icon="ion:close-outline" color={"#fff"} />*/}
        {/*  </Fab>*/}
        {/*</Stack>*/}

        <ShowAd ad={adBeforeSharing} />
        <ContactDetails
          shareOnly={shareOnly}
          adWhileShareScreen={adWhileShareScreen}
          closeShareScreen={closeShareScreen}
        />
      </Stack>
    </Fragment>
  );
}

export default BeforeShareScreen;

const forAdShowTime = 5000; //in milliseconds

const ShowAd = ({ ad }: { ad: string | undefined }) => {
  const { sharedImage } = useSelector((state) => state.fanpage);
  const [nextScreen, setNextScreen] = useState(false);
  const [hide, setHide] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    const timer = setTimeout(() => {
      setNextScreen(true);
    }, forAdShowTime);
    if (nextScreen && sharedImage) {
      setHide(true);
    }
    return () => clearTimeout(timer);
  }, [sharedImage, nextScreen]);

  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );
  useEffect(() => {
    if (ad) {
      saveDistributedCounter({
        campaignId: id,
        type: "ad_screen_1",
      });
    }
    return () => {};
  }, [ad]);

  return (
    <Stack
      position="absolute"
      zIndex="99999"
      height="100%"
      width="100%"
      sx={{
        display: hide ? "none" : "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#fff",
        color: "#000",
        img: {
          height: "-webkit-fill-available",
        },
      }}
    >
      {ad ? (
        // <img src={GetfullSizeCDNImageURL(ad)} alt="show ad" />
        <Stack
          width={"100%"}
          display={"flex"}
          flexGrow={1}
          direction={"column"}
          sx={{
            backgroundImage: `url(${GetfullSizeCDNImageURL(ad)})`,
            backgroundRepeat: `no-repeat`,
            backgroundSize: `auto 100vh`,
            backgroundPosition: "center",
            width: `100%`,
          }}
        ></Stack>
      ) : (
        <Typography
          sx={{
            height: "-webkit-fill-available",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          variant="h1"
        >
          Please wait
        </Typography>
      )}
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: "center",
          my: 2,
          form: {
            width: "100%",
          },
        }}
      >
        <CircularProgress sx={{ color: "#000" }} size="30px" />
        <Typography>Dein Post wird vorbereitet</Typography>
      </Stack>
    </Stack>
  );
};
