import { enumPublicationStatus } from "src/@types/campaigns";

const getDate = (date: string, timeZone: string) => {
  if (timeZone) {
    return new Date(date).toLocaleString("en-US", { timeZone: timeZone });
  } else {
    return new Date(date).toLocaleString("en-US");
  }
};
export const getPublicationStatus = (
  start_date: string,
  end_date: string,
  publish_now: boolean,
  timeZone: string
) => {
  let status = enumPublicationStatus.DRAFT;
  if (publish_now) {
    status = enumPublicationStatus.ACTIVE;
  } else {
    const nowDate = new Date().getTime();
    const stDate = new Date(getDate(start_date, timeZone)).getTime();
    const enDate = new Date(getDate(end_date, timeZone)).getTime();
    if (stDate <= nowDate && enDate >= nowDate) {
      status = enumPublicationStatus.ACTIVE;
    } else if (stDate > nowDate) {
      status = enumPublicationStatus.PLANNED;
    } else if (enDate < nowDate) {
      status = enumPublicationStatus.FINISHED;
    } else {
      status = enumPublicationStatus.DRAFT;
    }
    return status;
  }
  return status;
};
