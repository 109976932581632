import { createSlice, Dispatch } from "@reduxjs/toolkit";
import { IFanStore } from "src/@types/fan";

const initialState: IFanStore = {
  isLoading: false,
  error: null,
  sharedImage: "",
  tip: {
    team1: 0,
    team2: 0,
  },
};

const fanpage = createSlice({
  name: "fan",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setSharedImage(state, action) {
      state.isLoading = false;
      state.sharedImage = action.payload;
    },

    setTip(state, action) {
      state.isLoading = false;
      state.tip = action.payload;
    },
  },
});

// Reducer
export default fanpage.reducer;
const { startLoading, setSharedImage, setTip } = fanpage.actions;

export const set_shared_image = (image: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading);
    try {
      dispatch(setSharedImage(image));
    } catch (error) {
      console.error("set_shared_image error:", error);
    }
  };
};

export const set_tip_for_image = (tip: { team1: number; team2: number }) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading);
    try {
      dispatch(setTip(tip));
    } catch (error) {
      console.error("set_shared_image error:", error);
    }
  };
};
