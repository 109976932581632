import { createSlice, Dispatch } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  getDoc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  serverTimestamp,
  runTransaction,
} from "firebase/firestore";
import { ISaveSpaceObject, ISpaceType } from "src/@types/space";
import { db } from "src/firebase";
import { deleteSpaceLogo, uploadSpaceLogo } from "src/utils/firebaseStorage";

const initialState: any = {
  isLoading: false,
  spaces: [],
  spacebyid: null,
  totalCampaigns: [],
  createSpaceId: null,
};

const spaces = createSlice({
  name: "spaces",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // GET SPACES
    getSpacesSuccess(state, action) {
      state.isLoading = false;
      state.spaces = action.payload;
    },
    // with id
    getSpaceSuccess(state, action) {
      state.isLoading = false;
      state.spacebyid = action.payload;
    },
    getSpacesError(state) {
      state.isLoading = false;
    },
    //DELETE SPACES
    deleteSpacesSuccess(state) {
      state.isLoading = false;
    },
    // CREATE SPACE
    createSpaceSuccess(state) {
      state.isLoading = false;
    },
    updateSpaceSuccess(state) {
      state.isLoading = false;
    },
    spaceError(state) {
      state.isLoading = false;
    },
    //Total Campaigns	Count
    getTotalCampaignsSuccess(state, action) {
      state.totalCampaigns.push(action.payload);
      state.isLoading = false;
    },
    // create space id
    createSpaceIdSuccess(state, action) {
      state.createSpaceId = action.payload;
      state.isLoading = false;
    },
    createSpaceIdError(state) {
      state.isLoading = false;
    },
    resetSpaceId(state, action) {
      state.createSpaceId = action.payload;
      state.isLoading = false;
    },
  },
});

export default spaces.reducer;
const {
  startLoading,
  getSpacesSuccess,
  getSpaceSuccess,
  getSpacesError,
  deleteSpacesSuccess,
  createSpaceSuccess,
  getTotalCampaignsSuccess,
  createSpaceIdSuccess,
  createSpaceIdError,
  resetSpaceId,
} = spaces.actions;

const pathToUserSpaces = (uid: string) => {
  return `user_to_spaces/${uid}/spaces`;
};

const pathToSpace = () => {
  return `spaces/`;
};

const pathToSpaceWithId = (id: string) => {
  return `spaces/${id}`;
};

const pathToSaveFile = (uid: string, docId: string) => {
  return `${uid}/spaces/${docId}`;
};
const pathToGetTotalNumCampaign = (id: string) => {
  return `spaces/${id}/space_to_campaigns`;
};

export const get_user_spaces = (uid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const spacesRef = collection(db, pathToUserSpaces(uid));
      const first = query(spacesRef, orderBy("created", "desc"), limit(200));
      onSnapshot(first, (querySnapshot) => {
        let spacesArr: ISpaceType[] = [];
        querySnapshot.forEach((doc) => {
          const totalNumberRef = collection(
            db,
            pathToGetTotalNumCampaign(doc.id)
          );
          const totalNumber = query(totalNumberRef, limit(200));
          onSnapshot(totalNumber, (q) => {
            dispatch(
              getTotalCampaignsSuccess({
                id: doc.id,
                count: q.docs.length,
              })
            );
          });
          spacesArr.push({
            id: doc.id,
            name: doc.data().name,
            logo: doc.data().logo,
            num_campaigns: doc.data().num_campaigns,
          });
        });
        dispatch(getSpacesSuccess(spacesArr));
      });
    } catch (error) {
      dispatch(getSpacesError());
    }
  };
};

export const delete_user_spaces = (uid: string, doc_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    const spaceRaf = doc(db, pathToSpace(), doc_id);
    const docRaf = doc(db, pathToUserSpaces(uid), doc_id);
    try {
      await runTransaction(db, async (transaction) => {
        const doc = await transaction.get(docRaf);
        const spaceDoc = await transaction.get(spaceRaf);
        if (!doc.exists() && !spaceDoc.exists()) {
          return false;
        } else {
          transaction.delete(spaceRaf);
          transaction.delete(docRaf);

          dispatch(deleteSpacesSuccess());

          const { logo } = doc.data() || { logo: null };
          if (logo) {
            deleteSpaceLogo({
              path: pathToSaveFile(uid, doc_id),
              callBack: () => null,
            });
          }
        }
      });
    } catch (error) {
      console.error("For DELETE_USER_SPACES:", error);
    }
  };
};

export const create_new_space = (
  uid: string,
  doc_id: string,
  dataToStore: ISaveSpaceObject,
  callBack: () => void
) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const dbRef = doc(db, pathToUserSpaces(uid), doc_id);
      const createCallBack = async (logo: string | null) => {
        const docRef = doc(db, pathToUserSpaces(uid), doc_id);
        await setDoc(dbRef, {
          logo: logo,
          name: dataToStore.name,
          num_campaigns: 0,
          created: serverTimestamp(),
        });
        const isExist = await runTransaction(db, async (transaction) => {
          const doc = await transaction.get(docRef);
          if (!doc.exists()) {
            return false;
          } else {
            return true;
          }
        });
        if (isExist) {
          await setDoc(doc(db, pathToSpace(), doc_id), {
            ...dataToStore,
            logo,
          }).then(() => {
            dispatch(createSpaceSuccess());
            callBack();
          });
        }
      };

      if (dataToStore.logo && typeof dataToStore.logo !== "string") {
        const path = pathToSaveFile(uid, doc_id);
        uploadSpaceLogo({
          file: dataToStore.logo,
          path,
          callBack: createCallBack,
        });
      } else {
        createCallBack(dataToStore.logo);
      }
    } catch (error) {
      console.error("For CREATE_NEW_SPACE:", error);
      dispatch(getSpacesError());
    }
  };
};

export const update_space = (
  uid: string,
  doc_id: string,
  dataToStore: ISaveSpaceObject,
  callBack: () => void
) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const updateDocData: any = dataToStore;
      const dbRef = doc(db, pathToUserSpaces(uid), doc_id);
      const dbSpacesRef = doc(db, pathToSpace(), doc_id);
      const updateCallBack = async (logo: string | null) => {
        try {
          const isTransactionSuccess = await runTransaction(
            db,
            async (transaction) => {
              const sfDoc = await transaction.get(dbRef);
              const spaceDoc = await transaction.get(dbSpacesRef);
              if (!sfDoc.exists() && !spaceDoc.exists()) {
                return false;
              } else {
                //update data to user_to_spaces
                transaction.update(dbRef, {
                  logo: logo,
                  name: dataToStore.name,
                  num_campaigns: 0,
                });
                //update data to spaces
                transaction.update(dbSpacesRef, {
                  ...updateDocData,
                  logo,
                });
                return true;
              }
            }
          );

          if (isTransactionSuccess) {
            callBack();
          } else {
            throw "Error While Update";
          }
        } catch (e) {
          console.error("For UPDATE_SPACE:", e);
        }
      };

      if (dataToStore.logo && typeof dataToStore.logo !== "string") {
        const path = pathToSaveFile(uid, doc_id);
        uploadSpaceLogo({
          file: dataToStore.logo,
          path,
          callBack: updateCallBack,
        });
      } else {
        updateCallBack(dataToStore.logo);
      }
    } catch (error) {
      dispatch(getSpacesError());
      console.error("update_space error:", error);
    }
  };
};

export const get_space_byid = (doc_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const spaceRef = doc(db, pathToSpaceWithId(doc_id));
      const docSnap = await getDoc(spaceRef);
      dispatch(getSpaceSuccess(docSnap.data()));
    } catch (error) {
      dispatch(getSpacesError());
    }
  };
};

export const reset_space = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getSpaceSuccess(null));
  };
};

export const reset_create_space_id = () => {
  return async (dispatch: Dispatch) => {
    dispatch(resetSpaceId(null));
  };
};

export const create_space_id = (uid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const dbRef = doc(collection(db, pathToUserSpaces(uid)));
      dispatch(createSpaceIdSuccess(dbRef.id));
    } catch (error) {
      console.error("For CREATE_NEW_SPACE:", error);
      dispatch(createSpaceIdError());
    }
  };
};
