import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Fab,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { isIOS } from "react-device-detect";
import { useNavigate, useParams } from "react-router";
import Iconify from "src/components/iconify/Iconify";
import { dispatch, useSelector } from "src/redux/store";
import { GetfullSizeCDNImageURL } from "src/utils/cloudinaryImageUrl";
import DownloadDialog from "./DownloadDialog";
import AfterShareScreen from "./AfterShareScreen";
import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import functions from "src/utils/firebaseFunction";
import useCopyToClipboard from "src/hooks/useCopyToClipboard";
import { get_campaign_byid } from "src/redux/slices/campaigns";
import { createSharableImage } from "src/utils/createSharableImage";
import { drawingConfig } from "./const";
import { LoadingButton } from "@mui/lab";

const ShareScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { sharedImage } = useSelector((state) => state.fanpage);
  const [socialShare, setSocialShare] = useState(false);
  const [textCopyModal, setTextCopyModal] = useState(false);
  const [showSocialShareScrren, setShowSocialShareScrren] = useState(false);
  const {
    campaign: data,
    adConfing: adConfingData,
    participantsid,
  } = useSelector((state) => state.campaigns);
  const adConfig = adConfingData as any;
  const campaign = data as any;

  // connectFunctionsEmulator(functions, "localhost", 5001); // for the test on local only
  const saveSharePostStep = httpsCallable(functions, "saveSharePostStep");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!sharedImage) {
      navigate(`/s/${id}/intro`);
    }
    return () => {};
  }, [sharedImage]);

  useEffect(() => {
    if (id) {
      if (!campaign) {
        dispatch(get_campaign_byid(id));
      }
    }
    return () => {};
  }, [id, campaign]);

  const openCopyModal = async () => {
    setLoading(true);
    saveSharePostStep({
      campaignId: id,
      id: participantsid,
      shared_post_step_2: true,
      type: "shared_post_step_2",
    })
      .then((res: any) => {
        setTextCopyModal(false);
        setShowSocialShareScrren(true);
        setSocialShare(false);
        onFormatSelect();
        setLoading(false);
        return;
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });

    // setTextCopyModal(true);
  };

  const onSocialShareScreen = () => {
    setLoading(true);
    saveSharePostStep({
      campaignId: id,
      id: participantsid,
      shared_post_step_1: true,
      type: "shared_post_step_1",
    })
      .then((res: any) => {
        setSocialShare(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const onRedirect = () => {
    navigate(`/s/${id}/intro`);
  };
  const onBack = () => {
    setSocialShare(false);
  };
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const { copy } = useCopyToClipboard();
  const [isFormatSeleted, setIsFormatSeleted] = useState(false);
  const [showAd, setShowAd] = useState(false);
  const [showAfterShareAd, setShowAfterShareAd] = useState(false);
  const domain = window.location.origin;

  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );

  const copyAndShare = () => {
    copy(campaign.text + " " + `${window.location.origin}/dierecken`);
    setShowDownloadDialog(true);
  };
  const buildFilename = () => {
    const baseFilename = "MEIN_TIPP";
    const date = new Date().toLocaleDateString();
    return `${baseFilename}_${date}.png`;
  };

  const closeDownloadDialog = () => {
    setShowDownloadDialog(false);
  };

  const [isDownloaded, setIsDownloaded] = useState(false);
  const handleDownload = () => {
    onShare(isIOS);
    setTimeout(() => {
      setIsDownloaded(true);
    }, 1500);
  };

  const handleShare = () => {
    closeDownloadDialog();
    setIsFormatSeleted(true);
    onShare();
  };

  const downloadApi = (file: File) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(file);

    link.href = url;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
    setTimeout(() => {
      setShowAd(false);
      setShowAfterShareAd(true);
    }, 2500);
  };

  const shareApi = async (file: File) => {
    if (navigator.share) {
      setShowAd(true);

      let shareData = {
        title: "Die Recken Tippspiel",
        text: `${campaign.text} ${domain}/dierecken`,
        // url: `${domain}/dierecken`,
        files: [file],
      };
      // when we share on ios, we can only provide an image but not anything else. The reason is that
      // ios either takes text or an image and we put the focus on the image. To support the text, we
      // copy it to the clipboard
      if (isIOS) {
        // TODO: Create type, where title, text and url as optional
        // @ts-ignore
        shareData = {
          files: [file],
        };
        copy(campaign.text + " " + `${window.location.origin}/dierecken`);
        console.log("copying");
      }

      await navigator
        .share(shareData)
        .then(() => {
          setShowAd(false);
          setShowAfterShareAd(true);
        })
        .finally(() => {
          setShowAd(false);
          setShowAfterShareAd(true);
        })
        .catch((error) => {
          console.error("share error", error);
        });
    } else {
      downloadApi(file);
    }
  };

  const onShare = async (needDownload: boolean = false) => {
    // copy(campaign.text + " " + `${window.location.origin}/dierecken`);
    copy(campaign.text + " " + `https://lomavis.com/dierecken/go`);
    const image = GetfullSizeCDNImageURL(adConfig?.campaign_ad_8);
    if (image)
      await fetch(image)
        .then(function (response) {
          return response.blob();
        })
        .then(async function (blob) {
          var file = new File([blob], buildFilename(), { type: "image/png" });
          if (isIOS && needDownload) {
            //copy(campaign.text + " " + `${window.location.origin}/dierecken`);
            copy(campaign.text + " " + `https://lomavis.com/dierecken/go`);
            console.log("copying");
            downloadApi(file);
          } else {
            shareApi(file);
          }
        });
    // createSharableImage(image, newConfig).then(
    //     async (x: any) => {
    //         const imgurl = URL.createObjectURL(x);

    //     }
    // );
  };

  const onFormatSelect = () => {
    //copy(campaign.text + " " + `${window.location.origin}/dierecken`);
    copy(campaign.text + " " + `https://lomavis.com/dierecken/go`);
    console.log("copying");
    if (isIOS) {
      copyAndShare();
    } else {
      setIsFormatSeleted(true);
      onShare();
    }
    saveDistributedCounter({
      campaignId: id,
      type: "shares",
    });
  };
  const handleClose = () => {
    setTextCopyModal(false);
    setShowSocialShareScrren(true);
    setSocialShare(false);
    onFormatSelect();
  };
  return (
    <Fragment>
      <Stack
        position="absolute"
        zIndex="99999"
        height="100%"
        width="100%"
        sx={{
          img: {
            height: "100%",
            width: "100%",
          },
        }}
      >
        {!socialShare && !showSocialShareScrren && (
          <Stack position={"absolute"} top={"5px"} right={"10px"}>
            <Fab
              size="small"
              variant="outlined"
              color="default"
              aria-label="close"
              onClick={onRedirect}
            >
              <Iconify icon="ion:close-outline" color={"#fff"} />
            </Fab>
          </Stack>
        )}
        {!showSocialShareScrren && (
          <img
            src={GetfullSizeCDNImageURL(adConfig?.campaign_ad_6 || "")}
            alt="show ad"
            style={{
              filter: ` ${socialShare ? "blur(10px)" : "unset"}`,
            }}
          />
        )}
        {!socialShare && !showSocialShareScrren && (
          <Stack
            sx={{
              position: "absolute",
              bottom: "50px",
              left: "0",
              right: "0",
              padding: "0 15px",
            }}
          >
            <LoadingButton
              sx={{ textTransform: "none" }}
              variant="contained"
              onClick={onSocialShareScreen}
              loading={loading}
            >
              Jetzt teilen
            </LoadingButton>
          </Stack>
        )}
        {socialShare && (
          <Stack position={"absolute"} height={"100%"}>
            <Stack
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              width={"100vw"}
              marginTop={"10px"}
              padding={"0 10px"}
              marginBottom={"20px"}
            >
              <Fab
                size="small"
                variant="outlined"
                color="default"
                aria-label="back"
                onClick={onBack}
              >
                <Iconify icon="material-symbols:arrow-back" color={"#fff"} />
              </Fab>
              <Typography color={"#fff"}>Vorschau Beitrag</Typography>
              <Fab
                size="small"
                variant="outlined"
                color="default"
                aria-label="close"
                onClick={onRedirect}
              >
                <Iconify icon="ion:close-outline" color={"#fff"} />
              </Fab>
            </Stack>
            <Stack height={"100%"} width={"100%"}>
              <Stack
                id={"abc"}
                sx={{
                  height: "80%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  paddingBottom: "10%",
                }}
              >
                <Stack
                  maxWidth={"80%"}
                  maxHeight={"70%"}
                  margin={"0 auto"}
                  sx={{ aspectRatio: 9 / 16 }}
                >
                  <img
                    height={"100%"}
                    src={adConfig?.campaign_ad_8 || ""}
                    alt={"shared"}
                  />
                </Stack>
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"center"}
                  style={{
                    marginTop: "45px",
                  }}
                >
                  <LoadingButton
                    sx={{ textTransform: "none", width: "90%" }}
                    variant="contained"
                    onClick={openCopyModal}
                    loading={loading}
                  >
                    Plattform auswählen
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
        {textCopyModal && (
          <Stack position={"absolute"} zIndex={"99999"}>
            <CopyModal open={textCopyModal} handleClose={handleClose} />
          </Stack>
        )}
      </Stack>
      {showSocialShareScrren && (
        <Stack position={"relative"} zIndex={"99999999"}>
          {/* {showSocialShareScrren && !isFormatSeleted && (
              <FormatChoice onFormatSelect={onFormatSelect} />
            )} */}
          {isIOS && (
            <DownloadDialog
              open={showDownloadDialog}
              handleClose={closeDownloadDialog}
              handleDownload={handleDownload}
              handleShare={handleShare}
              isDownloaded={isDownloaded}
              textToCopy={
                //campaign.text + " " + `${window.location.origin}/dierecken`
                campaign.text + " " + `https://lomavis.com/dierecken/go`
              }
            />
          )}
          {isFormatSeleted && (
            <Fragment>{showAfterShareAd && <AfterShareScreen />}</Fragment>
          )}
        </Stack>
      )}
    </Fragment>
  );
};

export default ShareScreen;

interface Props {
  handleClose: () => void;
  open: boolean;
}
const CopyModal = ({ open, handleClose }: Props) => {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: "9999999",
      }}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Iconify
              icon="prime:copy"
              sx={{
                width: "70px",
                height: "70px",
                margin: "15px 0",
              }}
            />
            <Typography
              variant="caption"
              sx={{
                textAlign: "center",
                span: {
                  display: "block",
                  margin: "15px auto",
                  fontSize: "20px",
                },
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {" "}
                Text für Bildunterschrift wurde kopiert!
              </span>
              <span>
                {" "}
                Dein Text befindet sich als Kopie in desiner Zwischenablage.
                Füge ihn einfach unter deinem Foto ein.
              </span>
            </Typography>
          </Stack>
        </DialogContentText>
      </DialogContent>
      <Button
        style={{
          backgroundColor: "#40b8b6",
          color: "rgb(255, 255, 255)",
          textAlign: "center",
          textTransform: "capitalize",
          width: "90%",
          left: "23px",
          marginBottom: "35px",
          marginTop: "40px",
        }}
        onClick={handleClose}
      >
        {" "}
        Weiter
      </Button>
    </Dialog>
  );
};
