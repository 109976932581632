import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface Props {
  handleClose: () => void;
  handleDownload: () => void;
  handleShare: () => void;
  open: boolean;
  isDownloaded: boolean;
  textToCopy: string;
}

function DownloadDialog({
  open,
  isDownloaded,
  handleClose,
  handleDownload,
  handleShare,
  textToCopy,
}: Props) {
  return (
    <Dialog
      fullWidth
      open={open}
      onClose={handleClose}
      sx={{
        zIndex: "9999999999",
      }}
    >
      <DialogTitle>{"Text für deinen Beitrag"}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {textToCopy}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleShare} autoFocus>
          Kopieren & weiter
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DownloadDialog;
