import { CloudinaryImage } from "@cloudinary/url-gen";
import { thumbnail } from "@cloudinary/url-gen/actions/resize";
import { CLOUDINARY_CONFIG } from "src/config-global";

const GetCloudinaryImageURL = (
  imageUrl: string,
  height?: number,
  width?: number
) => {
  const img = new CloudinaryImage(imageUrl, CLOUDINARY_CONFIG)
    .setDeliveryType("fetch")
    .resize(
      thumbnail()
        .width(width || 40)
        .height(height || 40)
    )
    .format("png");

  return img.toURL();
};

export const getTablePreviewImageUrl = (imageUrl: string) => {
  return GetCloudinaryImageURL(imageUrl, 40, 40);
};

export const getSpaceMobileHeaderImageUrl = (imageUrl: string) => {
  return GetCloudinaryImageURL(imageUrl, 60, 60);
};

export const getPreviewImageUrl = (
  imageUrl: string,
  height?: number,
  width?: number
) => {
  return GetCloudinaryImageURL(imageUrl, height || 126, width || 126);
};

export const GetfullSizeCDNImageURL = (
  imageUrl: string,
  height?: number,
  width?: number
) => {
  const img = new CloudinaryImage(imageUrl, CLOUDINARY_CONFIG)
    .setDeliveryType("fetch")
    .format("png");

  return img.toURL();
};
