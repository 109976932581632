import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { List, Drawer, IconButton, Stack } from "@mui/material";
// config
import { NAV } from "../../../../config-global";
// components
import Logo from "../../../../components/logo";
import Iconify from "../../../../components/iconify";
import Scrollbar from "../../../../components/scrollbar";
import NavList from "./NavList";
import { NavProps } from "../types";
//

// ----------------------------------------------------------------------

export default function NavMobile({ isOffset, data }: NavProps) {
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          ml: 1,
          ...(isOffset && {
            color: "text.primary",
          }),
        }}
      >
        <Iconify icon="eva:menu-2-fill" />
      </IconButton>

      <Drawer
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_BASE,
          },
        }}
      >
        <Scrollbar>
          <Logo sx={{ mx: 2.5, my: 3 }} />

          <Stack display={"flex"} direction={"column"} justifyContent={"end"}>
            <List component="nav" disablePadding>
              {data.map((link) => (
                <NavList key={link.title} item={link} />
              ))}
            </List>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
