import { Suspense, lazy, ElementType } from "react";
// components
import LoadingScreen from "../components/loading-screen";

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import("../pages/LoginPage")));
export const RegisterPage = Loadable(
  lazy(() => import("../pages/RegisterPage"))
);
export const ForgotPasswordPage = Loadable(
  lazy(() => import("../pages/ForgotPasswordPage"))
);
export const NewPasswordPage = Loadable(
  lazy(() => import("../pages/NewPasswordPage"))
);

export const UserListPage = Loadable(
  lazy(() => import("../pages/dashboard/UserListPage"))
);

export const Page404 = Loadable(lazy(() => import("../pages/Page404")));

// MySpaces
export const MySpaces = Loadable(
  lazy(() => import("../pages/dashboard/spaces/MySpaces"))
);
export const NewSpaces = Loadable(
  lazy(() => import("../pages/dashboard/spaces/form/AddSpace"))
);

// Campaigns
export const Campaigns = Loadable(
  lazy(() => import("../pages/dashboard/campaigns/Campaigns"))
);
export const AddCampaign = Loadable(
  lazy(() => import("../pages/dashboard/campaigns/form/AddCampaign"))
);
export const SuccessCampaign = Loadable(
  lazy(() => import("../pages/dashboard/campaigns/CampaignSuccess"))
);
export const CampaignsDetails = Loadable(
  lazy(
    () =>
      import("../pages/dashboard/campaigns/campaignsDetail/CampaignsDetails")
  )
);

//Fan Pages
export const SharedDesktopScreen = Loadable(
  lazy(() => import("../pages/client/DesktopScreen/SharedDesktopScreen"))
);
export const FanPage = Loadable(lazy(() => import("../pages/client/FanPage")));
export const NotActiveScreen = Loadable(
  lazy(() => import("../pages/client/NotActiveScreen"))
);

// Find CampaignBy Slug name
export const FindCampaignBySlug = Loadable(
  lazy(() => import("../pages/FindCampaignBySlug"))
);

export const Introduction = Loadable(
  lazy(() => import("../pages/client/Introduction"))
);
// export const ActiveCampaign =  lazy(() => import("../pages/client/ActiveCampaign"))

// export const Register = Loadable(
//   lazy(() => import("../pages/client/share/Register"))
// );
// export const LoadingScreenOne = Loadable(
//   lazy(() => import("../pages/client/share/LoadingScreenOne"))
// );
