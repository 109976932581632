// routes
import { PATH_AUTH } from "../../../routes/paths";
// config
import { IMPRINT_LINK, PRIVACY_POLICY_URL } from "../../../config-global";
// components
import Iconify from "../../../components/iconify";

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: "Datenschutzerklärung",
    icon: <Iconify icon="eva:radio-button-on-fill" />,
    path: PRIVACY_POLICY_URL,
  },
  {
    title: "Impressum",
    icon: <Iconify icon="eva:menu-outline" />,
    path: IMPRINT_LINK,
  },
];

export default navConfig;
