// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import SvgColor from "../../../components/svg-color";
import { UserListPage } from "../../../routes/elements";

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: icon("ic_user"),
  ecommerce: icon("ic_ecommerce"),
  analytics: icon("ic_analytics"),
  dashboard: icon("ic_dashboard"),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: "",
    items: [
      { title: "Dashboard", path: PATH_DASHBOARD.space, icon: ICONS.dashboard },
      // {
      //   title: "Users",
      //   path: PATH_DASHBOARD.UserListPage,
      //   icon: ICONS.analytics,
      // },
    ],
  },
];

export default navConfig;
