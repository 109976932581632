import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router";
import QRCode from "react-qr-code";
import { useSnackbar } from "src/components/snackbar";
import useCopyToClipboard from "src/hooks/useCopyToClipboard";
import Iconify from "src/components/iconify/Iconify";

function QRCard() {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { copy } = useCopyToClipboard();
  const domain = window.location.origin;
  const onCopy = (text: string) => {
    if (text) {
      enqueueSnackbar("Copied!");
      copy(text);
    }
  };
  const [shortid, setShortid] = useState("");

  useEffect(() => {
    if (id) {
      setShortid(`${domain}/s/${id}` || `${domain}/s/`);
    }
    return () => {};
  }, [id]);
  return (
    <Stack
      sx={{ minWidth: "450px", p: 5 }}
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <div>
        <Typography sx={{ mb: 2, textAlign: "center" }} variant="h4">
          Jetzt scannen
        </Typography>
        <Typography
          sx={{ maxWidth: "400px" }}
          variant="body1"
          textAlign="center"
        >
          Öffne den Link mit deinem Handy
        </Typography>
      </div>

      <QRCode
        size={220}
        style={{ height: "220px", maxWidth: "100%", width: "220px" }}
        value={`${shortid}`}
        viewBox={`0 0 256 256`}
      />

      <TextField
        sx={{
          bgcolor: "#D8FBDE",
          mt: "40px !important",
        }}
        fullWidth
        value={`${shortid}`}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title="Copy">
                <IconButton
                  color="primary"
                  onClick={() => onCopy(`${shortid}`)}
                >
                  <Iconify icon="eva:share-fill" width={24} />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

export default QRCard;
