import { CssBaseline, Typography, TypographyProps } from "@mui/material";
import { ThemeProvider, createTheme, useTheme } from "@mui/material/styles";
import { merge } from "lodash";
import React, { useEffect, useMemo } from "react";

interface Props extends TypographyProps {
  id: string;
  children: string;
  fontPath?: string;
}

function TypographyWithFont({ id, children, variant, fontPath, sx }: Props) {
  const outerTheme = useTheme();

  const themeOptions = useMemo(
    () => ({
      typography: {
        fontFamily: id,
      },
      components: {
        MuiCssBaseline: {
          styleOverrides: `@font-face {
                        font-family: ${id};
                        font-display: swap;
                        src: url(${fontPath}) format('truetype');
                      }`,
        },
      },
    }),
    [fontPath]
  );

  const theme = createTheme(merge(outerTheme, themeOptions));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Typography
        variant={variant}
        sx={{
          ...sx,
          fontFamily: id,
        }}
      >
        {children}
      </Typography>
    </ThemeProvider>
  );
}

export default TypographyWithFont;
