import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import Lightbox from "src/components/lightbox/Lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";
import Skeleton from "@mui/material/Skeleton";
import { Slide } from "yet-another-react-lightbox/*";
import { useParams } from "react-router";
import { httpsCallable } from "firebase/functions";
import functions from "src/utils/firebaseFunction";

const styles = {
  container: {
    backgroundColor: "#282929",
  },
  icon: { backgroundColor: "white", color: "black", borderRadius: "100%" },
  root: { backgroundColor: "transparent" },
};

interface Props {
  images: Slide[] | undefined;
}

function ImageSlider({ images }: Props) {
  const { id } = useParams();
  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );
  useEffect(() => {
    saveDistributedCounter({
      campaignId: id,
      type: "image_formate",
    });

    return () => {};
  }, []);

  return (
    <Stack width="100%" display={"flex"} direction={"column"} flexGrow={1}>
      {images ? (
        <Lightbox
          slides={images}
          styles={{ ...styles }}
          toolbar={{ buttons: ["close"] }}
          plugins={[Inline]}
          inline={{
            style: {
              width: "100%",
              maxWidth: "900px",
              aspectRatio: "3 / 2",
              height: "100%",
            },
          }}
          render={{
            buttonPrev: images.length > 1 ? undefined : () => null,
            buttonNext: images.length > 1 ? undefined : () => null,
          }}
        />
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{ height: "100%" }}
          spacing={1}
        >
          <Skeleton
            variant="rectangular"
            width="90%"
            height="100%"
            animation="wave"
          />
        </Stack>
      )}
    </Stack>
  );
}

export default ImageSlider;
