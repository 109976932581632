import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Stack,
  Typography,
} from "@mui/material";
import { dispatch, useSelector } from "src/redux/store";
import { PRIVACY_POLICY_URL } from "../../../config-global";
import { get_campaign_byid } from "src/redux/slices/campaigns";
import { useParams } from "react-router";

interface Props {
  handleClose: () => void;
  open: boolean;
}

function PrivacyPolicyDialog({ open, handleClose }: Props) {
  const { campaign } = useSelector((state) => state.campaigns);
  const data: any = campaign;
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      if (!campaign) {
        dispatch(get_campaign_byid(id));
      } else {
        // setisLoading(false)
      }
    }
    return () => {};
  }, [id]);
  return (
    <Dialog fullWidth open={open} onClose={handleClose}>
      <DialogTitle>{`Teilnahme- und Datenschutzbedingungen`}</DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Stack>
            <div
              dangerouslySetInnerHTML={{ __html: data.privacy_policy }}
            ></div>
            <Stack>
              <a
                style={{ textDecoration: "none", color: "secondary" }}
                target="_blank"
                href={`${PRIVACY_POLICY_URL}`}
              >
                Lomavis Datenschutzerklärung
              </a>
            </Stack>
          </Stack>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PrivacyPolicyDialog;
