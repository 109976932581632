import { Simulate } from "react-dom/test-utils";
import load = Simulate.load;

const DRAWING_CONFIG_TYPE_TEXT = "text";
const DRAWING_CONFIG_TYPE_IMAGE = "image";
const DRAWING_CONFIG_TYPE_TIPPING_GAME_RESULT = "tipping_game_result";

export function createSharableImage(
  backgroundImageUrl: string,
  drawingConfig: any
) {
  return new Promise(async (resolve, reject) => {
    const img = document.createElement("img");
    img.crossOrigin = "anonymous";
    img.src = backgroundImageUrl;
    await img.decode();
    // img is ready to use

    const PADDING_IN_PX = 15;

    // Create a canvas element
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (ctx) {
      // draw background image
      const WIDTH = img.width;
      const HEIGHT = img.height;
      canvas.width = WIDTH;
      canvas.height = HEIGHT;
      ctx.drawImage(img, 0, 0);

      for (let c of drawingConfig) {
        const FONT_NAME = `LOADED_FONT_${c.id}`;
        let maxWidth = c.max_width_in_px || WIDTH - 2 * PADDING_IN_PX;

        let loadCustomFont =
          c.type == "text" || c.type == DRAWING_CONFIG_TYPE_TIPPING_GAME_RESULT;
        if (loadCustomFont) {
          if (c.font) {
            const font = new FontFace(FONT_NAME, `url(${c.font})`);
            await font.load();
            // @ts-ignore
            document.fonts.add(font);
          }
        }
        switch (c.type) {
          case DRAWING_CONFIG_TYPE_TEXT:
            ctx.font = `100px ${FONT_NAME}`;
            ctx.fillStyle = c.color;
            ctx.textAlign = "start";

            // we split the text in at new line chars
            let lines = c.text.split("\n");

            let xCoordinate = 0;
            let lineHeight = 0;
            let textWidth = 0;

            // check for each line that it is not wider than
            // the allowed max width
            for (let line of lines) {
              textWidth = ctx.measureText(line).width;

              // If the text width is greater than the width of the region,
              // scale down the font size until it fits
              while (textWidth >= maxWidth) {
                ctx.font = `${parseInt(ctx.font) - 1}px ${FONT_NAME}`;
                textWidth = ctx.measureText(line).width;
              }
            }

            for (let i = 0; i < lines.length; i++) {
              let line = lines[i];
              let textWidth = ctx.measureText(line).width;

              // retrieve line height to draw multiple lines properly
              let metrics = ctx.measureText(line);
              let actualHeight =
                metrics.actualBoundingBoxAscent +
                metrics.actualBoundingBoxDescent;
              lineHeight = actualHeight;

              // calculate right offset
              switch (c.position) {
                case "center":
                  xCoordinate = (WIDTH - textWidth) / 2;
                  break;
              }
              ctx.fillText(lines[i], xCoordinate, c.y + i * lineHeight * 1);
            }
            break;
          case DRAWING_CONFIG_TYPE_IMAGE:
            console.log("drawing image", c);
            if (!c.url) continue;
            let imageToDraw = new Image();
            imageToDraw.crossOrigin = "anonymous";
            imageToDraw.src = c.url;
            await imageToDraw.decode();
            ctx.drawImage(imageToDraw, c.x, c.y, c.width, c.height);
            break;
          case DRAWING_CONFIG_TYPE_TIPPING_GAME_RESULT:
            // draw 2 boxed
            // draw :
            // draw selected numbers
            let yCoordinate = c.y;

            let boxWidth = 275;
            let boxHeight = 200;

            let xCoordinateLeft = (WIDTH - maxWidth) / 2;
            let xCoordinateRight = WIDTH - (WIDTH - maxWidth) / 2 - boxWidth;

            let yCoordinateColon =
              yCoordinate + boxHeight / 2 + getLineHeight(ctx, ":") / 2;
            let xCoordinateColon =
              xCoordinateLeft +
              (xCoordinateRight + boxWidth - xCoordinateLeft) / 2 -
              getTextWidth(ctx, ":") / 2;

            ctx.fillText(":", xCoordinateColon, yCoordinateColon);

            let fontsizeLeftBox = drawNumberBox(
              ctx,
              c.value_left,
              xCoordinateLeft,
              yCoordinate,
              boxWidth,
              boxHeight,
              FONT_NAME,
              true,
              0
            );
            let fontsizeRightBox = drawNumberBox(
              ctx,
              c.value_right,
              xCoordinateRight,
              yCoordinate,
              boxWidth,
              boxHeight,
              FONT_NAME,
              true,
              0
            );

            drawNumberBox(
              ctx,
              c.value_left,
              xCoordinateLeft,
              yCoordinate,
              boxWidth,
              boxHeight,
              FONT_NAME,
              false,
              Math.max(...[fontsizeLeftBox, fontsizeRightBox])
            );
            drawNumberBox(
              ctx,
              c.value_right,
              xCoordinateRight,
              yCoordinate,
              boxWidth,
              boxHeight,
              FONT_NAME,
              false,
              Math.max(...[fontsizeLeftBox, fontsizeRightBox])
            );

            break;
        }
      }
    }
    // draw text

    // Convert the canvas to a Blob object
    canvas.toBlob(function (blob) {
      resolve(blob);
    });
  });

  function getTextWidth(ctx: CanvasRenderingContext2D, text: string) {
    return ctx.measureText(text).width;
  }

  function getLineHeight(ctx: CanvasRenderingContext2D, text: string) {
    let metrics = ctx.measureText(text);
    return metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
  }

  /**
   * We need this manual function to handle firefox. At the moment roundrect is not supported.
   * Draws a rounded rectangle using the current state of the canvas.
   * If you omit the last three params, it will draw a rectangle
   * outline with a 5 pixel border radius
   * @param {CanvasRenderingContext2D} ctx
   * @param {Number} x The top left x coordinate
   * @param {Number} y The top left y coordinate
   * @param {Number} width The width of the rectangle
   * @param {Number} height The height of the rectangle
   * @param {Number} [radius = 5] The corner radius; It can also be an object
   *                 to specify different radii for corners
   * @param {Number} [radius.tl = 0] Top left
   * @param {Number} [radius.tr = 0] Top right
   * @param {Number} [radius.br = 0] Bottom right
   * @param {Number} [radius.bl = 0] Bottom left
   * @param {Boolean} [fill = false] Whether to fill the rectangle.
   * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
   */
  function drawRoundRect(
    ctx: any,
    x: number,
    y: number,
    width: number,
    height: number,
    radius: any = 5,
    fill = false,
    stroke = true
  ) {
    if (typeof radius === "number") {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      radius = { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }

  function drawNumberBox(
    ctx: CanvasRenderingContext2D,
    number: any,
    x: number,
    y: number,
    width: number,
    height: number,
    fontName: string,
    simulate: boolean,
    fixedFontsize: number
  ): number {
    const PADDING_IN_PX = 50;
    // Draw the white background rectangle
    ctx.fillStyle = "white";
    ctx.strokeStyle = "white";

    drawRoundRect(ctx, x, y, width, height, 25, true, true);
    //ctx.beginPath();
    //ctx.roundRect(x, y, width, height, 25);
    //ctx.stroke();
    //ctx.fill();

    // Set the font and text alignment
    ctx.font = `100px ${fontName}`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "black";

    if (!simulate) {
    }
    let fontSizeScaleFactor = 0.5;
    let scaledFontSize = null;

    // Check if the number fits within the box
    while (ctx.measureText(number).width > width) {
      // Decrease the font size until the number fits within the box
      ctx.font = `${parseInt(ctx.font) - 1}px ${fontName}`;
    }
    let measure = ctx.measureText(number);
    let fontSize =
      (parseInt(ctx.font) * width) /
      (measure.actualBoundingBoxLeft + measure.actualBoundingBoxRight);

    // in case we habe a single digit number, the image is higher and wider. That's why need to switch here
    // to use the height instead of the width to calculate the new fontsize.
    if (number < 10) {
      fontSize =
        (parseInt(ctx.font) * height) /
        (measure.actualBoundingBoxDescent + measure.actualBoundingBoxAscent);
    }
    scaledFontSize = fontSize * fontSizeScaleFactor;

    if (!simulate) {
      ctx.font = `${fixedFontsize}px ${fontName}`;
    } else {
      ctx.font = `${scaledFontSize}px ${fontName}`;
    }

    measure = ctx.measureText(number);

    // Draw the number centered inside the box
    const xPos =
      x +
      width / 2 +
      (measure.actualBoundingBoxLeft - measure.actualBoundingBoxRight) / 2;

    const yPos =
      y +
      height / 2 +
      (measure.actualBoundingBoxAscent - measure.actualBoundingBoxDescent) / 2;

    if (!simulate) {
      ctx.fillText(number, xPos, yPos);
    }

    return scaledFontSize;
  }
}
