import React, { useEffect, useState } from "react";
import { set_shared_image } from "src/redux/slices/fan";
import { dispatch, useSelector } from "src/redux/store";
import { GetfullSizeCDNImageURL } from "src/utils/cloudinaryImageUrl";
import { createSharableImage } from "src/utils/createSharableImage";
import { drawingConfig } from "../share/const";

function useCreateSharableImage(data: any) {
  const [image, setImage] = useState("");
  const { tip } = useSelector((state) => state.fanpage);

  useEffect(() => {
    if (data) {
      const backgroundImageUrl = GetfullSizeCDNImageURL(
        data.media_asset_image_story_format
      );
      const campaignContent: [] = data.config_section;
      const newConfig = data?.drawingConfig
        ? JSON.parse(data?.drawingConfig)
        : drawingConfig;
      drawingConfig.forEach((config, index) => {
        if (config.for !== null) {
          const x: any = campaignContent.find(
            (x: any) => x.dataforId === config.for
          );
          if (config.type == "text") {
            newConfig[index].id = `${new Date().getTime()}`;
            newConfig[index].text = x.text;
            newConfig[index].color = x.color;
            newConfig[index].font = x.font.value;
          } else if (config.type == "image") {
            newConfig[index].url = GetfullSizeCDNImageURL(x.image);
          }
        }
        if (config.id === "tipping") {
          newConfig[index].value_left = tip.team1;
          newConfig[index].value_right = tip.team2;
        }
      });
      createSharableImage(backgroundImageUrl, newConfig).then(
        async (x: any) => {
          const imgurl = URL.createObjectURL(x);
          setImage(imgurl);
          dispatch(set_shared_image(imgurl));
          // props.setBtn(false);
        }
      );
    }
    return () => {};
  }, [data]);

  return { image };
}

export default useCreateSharableImage;
