import { Navigate, useRoutes } from "react-router-dom";
// auth
import AuthGuard from "../auth/AuthGuard";
import GuestGuard from "../auth/GuestGuard";
// layouts
import CompactLayout from "../layouts/compact";
import DashboardLayout from "../layouts/dashboard";
// config
import { PATH_AFTER_LOGIN } from "../config-global";
//
import {
  Page404,
  LoginPage,
  UserListPage,
  MySpaces,
  NewSpaces,
  Campaigns,
  AddCampaign,
  SuccessCampaign,
  SharedDesktopScreen,
  FanPage,
  NotActiveScreen,
  CampaignsDetails,
  RegisterPage,
  ForgotPasswordPage,
  NewPasswordPage,
  FindCampaignBySlug,
  Introduction,
} from "./elements";
import CommonClientLayout from "src/pages/client/CommonClientLayout";
import ActiveCampaign from "src/pages/client/ActiveCampaign";
import Register from "src/pages/client/share/Register";
import ShareScreen from "src/pages/client/share/ShareScreen";
import LoadingScreenOne from "src/pages/client/share/LoadingScreenOne";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      children: [
        {
          path: "/s/:id/",
          element: <CommonClientLayout />,
          children: [
            { index: true, element: <FanPage /> },
            { path: "intro", element: <Introduction /> },
            { path: "tipp", element: <ActiveCampaign /> },
            { path: "register", element: <Register /> },
            { path: "loading1", element: <LoadingScreenOne /> },
            { path: "share-screen", element: <ShareScreen /> },
          ],
        },
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: "login",
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: "space", element: <MySpaces /> },
        { path: "space/create", element: <NewSpaces /> },
        { path: "space/:id/edit", element: <NewSpaces /> },
        { path: "space/:id/campaigns", element: <Campaigns /> },
        { path: "space/:id/campaigns/create", element: <AddCampaign /> },
        {
          path: "space/:id/campaigns/:campaingid/edit",
          element: <AddCampaign />,
        },
        {
          path: "space/:id/campaigns/:campaingid/success",
          element: <SuccessCampaign />,
        },
        { path: "UserListPage", element: <UserListPage /> },
        {
          path: "space/:id/campaigns/:campaingid/details",
          element: <CampaignsDetails />,
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "/s/:id/not-active", element: <NotActiveScreen /> },
        {
          path: "/forgot-password",
          element: <ForgotPasswordPage />,
        },
        {
          path: "/new-password",
          element: <NewPasswordPage />,
        },
        { path: "/:slug", element: <FindCampaignBySlug /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
