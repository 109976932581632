import { yupResolver } from "@hookform/resolvers/yup";
import { Fab, Stack, Typography } from "@mui/material";
import React, { useState, useEffect, Fragment, useRef } from "react";
import FormProvider from "src/components/hook-form/FormProvider";
import * as Yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { RHFCheckbox, RHFTextField } from "src/components/hook-form";
import { LoadingButton } from "@mui/lab";
import { connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { dispatch, useSelector } from "src/redux/store";
import { useNavigate, useParams } from "react-router";
import {
  get_campaign_byid,
  set_adConfing_data,
  store_participants_id,
} from "src/redux/slices/campaigns";
import Header from "../Header";
import PrivacyPolicyDialog from "./PrivacyPolicyDialog";
import useCreateSharableImage from "../hook/useCreateSharableImage";
import functions from "src/utils/firebaseFunction";

const styles = {
  textField: {
    root: {
      borderRadius: 0,
    },
    input: {
      height: "30px",
      padding: "10px 15px",
      background: "#fff",
      color: "#000",
      borderRadius: "10px",
    },
  },
};

function Register() {
  const defaultValues = JSON.parse(
    localStorage.getItem("participant") || "{}"
  ) || {
    email: "",
    name: "",
    newsletter_accepted: false,
    checkbox1: false,
  };
  const ContactDetailSchema = Yup.object().shape({
    name: Yup.string().required("Dein Name wird benötigt"),
    email: Yup.string()
      .email("Bitte gib eine gültige E-Mail-Adresse ein")
      .required("Bitte gib eine gültige E-Mail-Adresse ein"),
  });

  const { id } = useParams();
  const methods = useForm({
    resolver: yupResolver(ContactDetailSchema),
    defaultValues,
  });
  const { control } = methods;
  const [showDialog, setShowDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const OpenDialog = () => {
    setShowDialog(true);
  };
  const { campaign: data } = useSelector((state) => state.campaigns);
  const campaign = data as any;
  const { tip } = useSelector((state) => state.fanpage);
  const { sharedImage } = useSelector((state) => state.fanpage);
  const { image } = useCreateSharableImage(campaign);
  const { spacebyid } = useSelector((state) => state.spaces);
  const Space = spacebyid as any;
  const [adConfig, setAdConfig] = useState({
    campaign_ad_1: "",
    campaign_ad_2: "",
    campaign_ad_3: "",
    campaign_ad_4: "",
    campaign_ad_5: "",
    campaign_ad_6: "",
    campaign_ad_7: "",
    campaign_ad_8: "",
  });
  const [showVideo, setShowVideo] = useState(false);
  useEffect(() => {
    if (campaign) {
      campaign?.ad_config.forEach((element: any) => {
        if (element?.dataforId === "campaign_ad_1" && element?.video) {
          setShowVideo(true);
        }
        setAdConfig((old) => ({
          ...old,
          [element.dataforId]: element?.video ? element?.video : element?.image,
        }));
      });
    }
    return () => {};
  }, [campaign]);

  useEffect(() => {
    if (adConfig) {
      dispatch(set_adConfing_data(adConfig, showVideo));
    }
    return () => {};
  }, [adConfig]);

  useEffect(() => {
    if (tip.team1 === 0 && tip.team2 === 0) {
      navigate(`/s/${id}/tipp`);
    }
    return () => {};
  }, [tip]);

  useEffect(() => {
    if (id) {
      if (!campaign) {
        dispatch(get_campaign_byid(id));
      }
    }
    return () => {};
  }, [id]);

  // connectFunctionsEmulator(functions, "localhost", 5001); // for the test on local only
  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );

  const onSubmit = (values: any) => {
    // const functions = getFunctions(getApp());
    setIsLoading(true);
    // connectFunctionsEmulator(functions, "localhost", 5001); // for the test on local only
    const saveParticipants = httpsCallable(functions, "saveParticipants");
    const dataToStorage = {
      email: values.email.toLowerCase(),
      name: values.name,
      newsletter_accepted: false,
      checkbox1: false,
    };
    localStorage.setItem("participant", JSON.stringify(dataToStorage));

    saveParticipants({
      email: values.email.toLowerCase(),
      name: values.name,
      campaignId: id,
      tip: `${tip.team1}:${tip.team2}`,
      newsletter_accepted: values.newsletter_accepted || false,
      checkbox1: values.checkbox1 || false,
    })
      .then((result: any) => {
        if (result?.data?.id) {
          dispatch(store_participants_id(result.data.id));
        }
        setIsLoading(false);
        saveDistributedCounter({
          campaignId: id,
          type: "registrations",
        });
        saveDistributedCounter({
          campaignId: id,
          type: "contact_page",
        });
        navigate(`/s/${id}/loading1`);
      })
      .catch((error) => {
        console.error("firebase funtion error:", error);
        setIsLoading(false);
      });
  };

  const emailWatch: string = useWatch({
    name: "email",
    control,
  });
  const nameWatch: string = useWatch({
    name: "name",
    control,
  });

  const refShareImg = useRef(null);

  const CloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <Fragment>
      <Header />
      <Stack
        sx={{ mt: 2 }}
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        height="calc(100% - 57px)"
      >
        <Stack
          sx={{
            px: 3,
            height: `60%`,
            overflow: `hidden`,
            img: {
              objectFit: "contain",
              height: `100%`,
            },
          }}
        >
          <img ref={refShareImg} src={adConfig.campaign_ad_7} alt="" />
        </Stack>
        <Stack
          sx={{
            padding: "16px",
            width: "100%",
            form: {
              width: "100%",
            },
            borderRadius: "15px 15px 0 0",
          }}
          bgcolor={"#282929"}
        >
          <Typography
            variant="caption"
            sx={{
              textAlign: "center",
              span: {
                width: "75%",
                display: "block",
                margin: "0 auto",
                color: "#fff",
                marginBottom: "15px",
              },
            }}
          >
            <span>
              Bitte gib deine Kontaktdaten an, damit wir dich benachrichtigen
              können.
            </span>
          </Typography>
          {true && (
            <FormProvider
              methods={methods}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <Stack width="100%" spacing={2}>
                <RHFTextField
                  sx={styles.textField}
                  name="name"
                  placeholder="Name"
                />
                <RHFTextField
                  sx={styles.textField}
                  name="email"
                  placeholder="E-Mail"
                />

                <Typography
                  variant="caption"
                  sx={{
                    textAlign: "center",
                    b: {
                      color: "#5393C0",
                    },
                    span: {
                      width: "75%",
                      display: "block",
                      margin: "0 auto",
                      color: "#fff",
                    },
                  }}
                >
                  <span onClick={OpenDialog}>
                    Mit dem Klicken auf <strong>Jetzt teilnehmen</strong> stimme
                    ich den
                    <b> Teilnahme- und Datenschutzbedingungen</b> zu.
                  </span>
                </Typography>
                {campaign?.choice?.checkbox1 && (
                  <Stack
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    marginTop={"0px !important"}
                  >
                    <RHFCheckbox
                      label={campaign?.choice?.checkbox1_lable}
                      name={"checkbox1"}
                      sx={{
                        span: {
                          marginRight: "0px",
                          display: "block",
                          margin: "0 auto",
                          color: "#fff",
                        },
                      }}
                    />
                  </Stack>
                )}
                {campaign?.choice?.newsletter && (
                  <Stack
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    flexDirection={"row"}
                    marginTop={"0px !important"}
                  >
                    <RHFCheckbox
                      label={
                        campaign?.choice?.newsletter_lable
                          ? campaign?.choice?.newsletter_lable
                          : "Ich möchte zukünftig den Newsletter erhalten"
                      }
                      name={"newsletter_accepted"}
                      sx={{
                        span: {
                          marginRight: "0px",
                          display: "block",
                          margin: "0 auto",
                          color: "#fff",
                        },
                      }}
                    />
                  </Stack>
                )}

                <LoadingButton
                  sx={{ textTransform: "none" }}
                  variant="contained"
                  type="submit"
                  disabled={emailWatch && nameWatch ? false : true}
                  loading={isLoading}
                >
                  Jetzt teilnehmen
                </LoadingButton>
              </Stack>
            </FormProvider>
          )}
        </Stack>
      </Stack>
      {showDialog && (
        <PrivacyPolicyDialog open={showDialog} handleClose={CloseDialog} />
      )}
    </Fragment>
  );
}

export default Register;
