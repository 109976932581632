import { Button, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "src/redux/store";
import { GetfullSizeCDNImageURL } from "src/utils/cloudinaryImageUrl";
import { isAndroid, isDesktop, isIOS, isMobile } from "react-device-detect";
import { httpsCallable } from "firebase/functions";
import { useNavigate, useParams } from "react-router";
import functions from "src/utils/firebaseFunction";

function AfterShareScreen() {
  const { campaign, isLoading }: any = useSelector((state) => state.campaigns);
  const [image, setImage] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();
  const saveDistributedCounter = httpsCallable(
    functions,
    "saveDistributedCounter"
  );
  useEffect(() => {
    if (campaign) {
      const data = campaign.ad_config.find(
        (x: { dataforId: string }) => x.dataforId === "campaign_ad_3"
      );
      setImage(data.image);
    }
    saveDistributedCounter({
      campaignId: id,
      type: "ad_screen_3",
    });
    return () => {};
  }, []);

  const followCTA = () => {
    const fallBackLink = "https://instagram.com/dierecken";

    let scheme = "instagram://user?username=dierecken";
    let isInstalled = false;

    window.location.href = scheme;

    setTimeout(function () {
      window.location.href = fallBackLink;
    }, 250);

    window.addEventListener("pagehide", function () {
      isInstalled = true;
    });

    window.addEventListener("blur", function () {
      if (!isInstalled) {
        window.location.href = fallBackLink;
      }
    });
  };

  /*
   * Reloads the page so that the user can start again the campaign.
   * */
  const startOver = (e: any) => {
    e.stopPropagation();
    // window.location.reload();
    navigate(`/s/${id}`);
  };

  return (
    <>
      <Stack
        onClick={followCTA}
        position="absolute"
        height={window.innerHeight}
        width={window.innerWidth}
        sx={{
          display: "flex",
          alignItems: "end",
          width: `100%`,
          height: `100vh`,
          backgroundImage: `url(${GetfullSizeCDNImageURL(image)})`,
          backgroundRepeat: `no-repeat`,
          backgroundPosition: "center",
          backgroundSize: `cover`,
        }}
      >
        <Stack sx={{ marginTop: "40px", mr: 1 }}>
          <Button onClick={startOver} variant="contained" size="small">
            Neuer Tipp
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default AfterShareScreen;
