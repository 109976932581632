import { getApp } from "firebase/app";
import { getFunctions } from "firebase/functions";

function firebaseFunction() {
  return getFunctions(getApp(), "europe-west3");
}

const functions = firebaseFunction();

export default functions;
