import { AppBar, Link, Stack, Toolbar } from "@mui/material";
import React from "react";
import { HEADER } from "src/config-global";
import { useTheme } from "@mui/material/styles";
import { bgBlur } from "src/utils/cssStyles";
import { IMPRINT_LINK, PRIVACY_POLICY_URL } from "src/config-global";

function Footer() {
  const theme = useTheme();
  return (
    <AppBar
      color="transparent"
      sx={{
        boxShadow: 0,
        top: "auto",
        bottom: 0,
      }}
    >
      <Toolbar
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: "40px",
          },
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...bgBlur({ color: theme.palette.background.default }),
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          direction={"row"}
          width="100%"
          spacing={2}
        >
          <Link
            target="_blank"
            href={IMPRINT_LINK}
            variant="subtitle2"
            color="inherit"
          >
            Impressum
          </Link>
          <Link
            target="_blank"
            href={PRIVACY_POLICY_URL}
            variant="subtitle2"
            color="inherit"
          >
            Datenschutzerklärung
          </Link>
        </Stack>
      </Toolbar>
    </AppBar>
  );
}

export default Footer;
