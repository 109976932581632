import { Card, Stack, TextField, Typography } from "@mui/material";
import React, { useState, Fragment, useEffect } from "react";
import { IFanResponse } from "src/@types/fan";
import { getPreviewImageUrl } from "src/utils/cloudinaryImageUrl";
import { preloadMediaAssets } from "src/utils/preloadMediaAssets";
import TypographyWithFont from "../TypographyWithFont";

const styles = {
  textField: {
    background: "#fff",
    color: "#000",
    input: {
      width: "80px",
      height: "80px",
      padding: "0 5px",
      fontSize: "42px",
      textAlign: `center`,
    },
  },
};

interface Props {
  data: IFanResponse;
}

function PreviewCampaign({ data }: Props) {
  const [adConfig, setAdConfig] = useState({
    campaign_ad_1: "",
    campaign_ad_2: "",
    campaign_ad_3: "",
    campaign_ad_4: "",
  });

  const [content, setContent] = useState({
    campaign_content_1: "",
    campaign_content_2: "",
    campaign_content_3: "",
    campaign_content_4: "",
  });

  const [contentColor, setContentColor] = useState({
    campaign_content_1: "",
    campaign_content_2: "",
    campaign_content_3: "",
    campaign_content_4: "",
  });
  const [contentFont, setContentFont] = useState({
    campaign_content_1: "",
    campaign_content_2: "",
    campaign_content_3: "",
    campaign_content_4: "",
  });
  const [isContentImage, setIsContentImage] = useState({
    campaign_content_1: false,
    campaign_content_2: false,
    campaign_content_3: false,
    campaign_content_4: false,
  });

  useEffect(() => {
    console.log(data);
    if (data) {
      data.ad_config.forEach((element) => {
        setAdConfig((old) => ({
          ...old,
          [element.dataforId]: element.image,
        }));
      });

      data.config_section.forEach((element) => {
        setIsContentImage((old) => ({
          ...old,
          [element.dataforId]:
            (element.image && true) || (element.text && false),
        }));
        setContentColor((old) => ({
          ...old,
          [element.dataforId]: element.color,
        }));
        setContentFont((old) => ({
          ...old,
          [element.dataforId]: element.font?.value,
        }));
        setContent((old) => ({
          ...old,
          [element.dataforId]: element.image || element.text,
        }));
      });
      preloadMediaAssets(data);
    }
    return () => {};
  }, [data]);
  return (
    <Fragment>
      <Card
        id="PreviewCampaignCard"
        sx={{
          padding: `0px`,
          backgroundImage: `url(${data?.media_asset_image_story_format})`,
          backgroundRepeat: `no-repeat`,
          backgroundSize: `auto 100vh`,
          width: `100%`,
          height: `600px`,
          color: "#fff",
          borderRadius: 1,
          backgroundPosition: "center",
          transform: "scale(0.8)",
        }}
      >
        <Stack
          id="PreviewCampaign"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          sx={{
            transform: "scale(0.8)",
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <TypographyWithFont
              id="campaign_content_1"
              variant="h1"
              fontPath={contentFont.campaign_content_1}
              sx={{
                textTransform: "uppercase",
                fontSize: "13vw",
                color: contentColor.campaign_content_1,
              }}
            >
              {content.campaign_content_1}
            </TypographyWithFont>

            <TypographyWithFont
              id="campaign_content_2"
              variant="h1"
              fontPath={contentFont.campaign_content_2}
              sx={{
                textTransform: "uppercase",
                fontSize: "13vw",
                borderTop: "1px solid #fff",
                color: contentColor.campaign_content_2,
              }}
            >
              {content.campaign_content_2}
            </TypographyWithFont>

            {adConfig.campaign_ad_4 && (
              <Stack alignItems="center">
                <Typography variant="body2">präsentiert von:</Typography>
                <img
                  src={getPreviewImageUrl(adConfig.campaign_ad_4, 100, 100)}
                  alt=""
                />
              </Stack>
            )}

            <Typography
              variant="h3"
              sx={{
                pt: 7,
                textTransform: "uppercase",
              }}
            >
              HIER TIPP EINGEBEN:
            </Typography>

            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              width="100%"
              sx={{
                py: 2,
                img: {
                  height: "100px",
                  width: "100px",
                },
              }}
            >
              {isContentImage.campaign_content_3 ? (
                <img
                  src={getPreviewImageUrl(content.campaign_content_3)}
                  alt=""
                />
              ) : (
                <Typography variant="h3">
                  {content.campaign_content_3}
                </Typography>
              )}

              <Typography variant="h1"> VS </Typography>

              {isContentImage.campaign_content_4 ? (
                <img
                  src={getPreviewImageUrl(content.campaign_content_4)}
                  alt=""
                />
              ) : (
                <Typography variant="h3">
                  {content.campaign_content_4}
                </Typography>
              )}
            </Stack>

            <Stack
              alignItems="center"
              justifyContent="space-between"
              direction="row"
              sx={{
                mt: 1,
                width: "100%",
                form: {
                  width: "100%",
                },
              }}
            >
              <Stack
                alignItems="center"
                justifyContent="space-between"
                direction="row"
                sx={{
                  width: "100%",
                }}
              >
                <Typography variant="h3">
                  <TextField
                    sx={styles.textField}
                    name="team1"
                    variant="filled"
                    disabled
                  />
                </Typography>
                <Typography variant="h2"> : </Typography>
                <Typography variant="h3">
                  <TextField
                    sx={styles.textField}
                    name="team1"
                    variant="filled"
                    disabled
                  />
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Fragment>
  );
}

export default PreviewCampaign;
