import { GetfullSizeCDNImageURL } from "./cloudinaryImageUrl";

/*
 * Preload images into the browser's url cache
 * */
async function preloadImageToCache(url: string) {
  let image = new Image();
  image.src = url;
}

/*
 * Preload all assets of a campaign into the browser's cache
 * */
export async function preloadMediaAssets(campaign: any) {
  const MEDIA_ASSETS = [
    "media_asset_image_story_format",
    "media_asset_image_square_format",
    "media_asset_image_portrait_format",
  ];

  // preload media assets such as story format, square and portrait
  for (let mediaAsset of MEDIA_ASSETS) {
    if (!campaign[mediaAsset]) continue;
    preloadImageToCache(GetfullSizeCDNImageURL(campaign[mediaAsset]));
  }

  // preload images from the configs
  for (let config of campaign.config_section) {
    if (!config.image) continue;
    preloadImageToCache(GetfullSizeCDNImageURL(config.image));
  }

  // preload ad screens
  for (let adConfig of campaign.ad_config) {
    if (!adConfig.image) continue;
    preloadImageToCache(GetfullSizeCDNImageURL(adConfig.image));
  }
}
