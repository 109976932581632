import { createSlice, Dispatch } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  serverTimestamp,
  runTransaction,
  getDoc,
} from "firebase/firestore";
import {
  enumPublicationStatus,
  ILotteryParticipantsForCampaignType,
} from "src/@types/campaigns";
import { db } from "src/firebase";
import { deleteCampaignAllImages } from "src/utils/firebaseStorage";
import { getPublicationStatus } from "src/utils/getPublicationStatus";
const sHash = require("string-hashids");

const initialState = {
  isLoading: false,
  campaigns: [],
  campaign: null,
  spaceByIdData: null,
  lotteryParticipantsForCampaign: null,
  getCounter: null,
  publicationsCounter: null,
  winnerLoading: false,
  winnerUser: {},
  adConfing: {},
  adConfingOneHasVideo: false,
  participantsid: null,
};

const campaigns = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // GET SPACES
    getCampaignsSuccess(state, action) {
      state.isLoading = false;
      state.campaigns = action.payload;
    },
    // with id
    getCampaignSuccess(state, action) {
      state.isLoading = false;
      state.campaign = action.payload;
    },
    getCampaignsError(state) {
      state.isLoading = false;
    },
    //DELETE SPACES
    deleteCampaignsSuccess(state) {
      state.isLoading = false;
    },
    // CREATE SPACE
    createCampaignSuccess(state) {
      state.isLoading = false;
    },
    updateCampaignsSuccess(state) {
      state.isLoading = false;
    },
    CampaignsError(state) {
      state.isLoading = false;
    },
    // get space with id
    getSpaceByIdSuccess(state, action) {
      state.isLoading = false;
      state.spaceByIdData = action.payload;
    },
    getSpacesByIdError(state) {
      state.isLoading = false;
    },
    //get Lottery Participants For campaign by campaign id
    getLotteryParticipantsForCampaignSuccess(state, action) {
      state.isLoading = false;
      state.lotteryParticipantsForCampaign = action.payload;
    },
    getLotteryParticipantsForCampaignError(state) {
      state.isLoading = false;
    },
    //get counter by campaign id
    getCounterCampaignSuccess(state, action) {
      state.isLoading = false;
      state.getCounter = action.payload;
    },
    getCounterCampaignError(state) {
      state.isLoading = false;
    },
    //get Publications counter
    getPublicationsCounterSuccess(state, action) {
      state.isLoading = false;
      state.publicationsCounter = action.payload;
    },
    getPublicationsCounterError(state) {
      state.isLoading = false;
    },
    // winner logs
    startWinnerLoading(state) {
      state.winnerLoading = true;
    },
    addWinner(state) {
      state.winnerLoading = false;
    },
    getWinnerUserSuccess(state, action) {
      state.isLoading = false;
      state.winnerUser = action.payload;
    },
    getWinnerUserError(state) {
      state.isLoading = false;
    },
    setAdConfingData(state, action) {
      state.adConfing = action.payload.adConfing;
      state.adConfingOneHasVideo = action.payload.hasVideo;
    },
    setParticipantsid(state, action) {
      state.participantsid = action.payload;
    },
  },
});

export default campaigns.reducer;
const {
  startLoading,
  getCampaignsSuccess,
  getCampaignSuccess,
  getCampaignsError,
  deleteCampaignsSuccess,
  createCampaignSuccess,
  getSpaceByIdSuccess,
  getSpacesByIdError,
  getLotteryParticipantsForCampaignError,
  getLotteryParticipantsForCampaignSuccess,
  getCounterCampaignSuccess,
  getCounterCampaignError,
  getPublicationsCounterSuccess,
  getPublicationsCounterError,
  addWinner,
  startWinnerLoading,
  getWinnerUserSuccess,
  getWinnerUserError,
  setAdConfingData,
  setParticipantsid,
} = campaigns.actions;

const pathToSpaceCampaigns = (id: string) => {
  return `spaces/${id}/space_to_campaigns`;
};

const pathToCampaigns = () => {
  return `campaigns/`;
};

const pathToCampaignWithId = (id: string) => {
  return `campaigns/${id}`;
};

const pathToCampaignStorage = (uid: string, id: string) => {
  return `/files/${uid}/campaigns/${id}/`;
};

const pathToSpaceWithId = (id: string) => {
  return `spaces/${id}`;
};

const pathToLotteryParticipantsForCampaign = (id: string) => {
  return `/lottery_participants_for_campaign/${id}/participants/`;
};
const pathToCounterForCampaign = (id: string) => {
  return `/lottery_participants_for_campaign/${id}/`;
};

const pathToWinner = (id: string) => {
  return `/lottery_participants_for_campaign/${id}/`;
};

export const get_count_publications = () => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const counterRef = collection(db, "/lottery_participants_for_campaign");
      const snapShort = query(counterRef, limit(200));
      onSnapshot(snapShort, (querySnapshot) => {
        let counterArray: any = [];
        querySnapshot.forEach((doc) => {
          counterArray.push({
            id: doc.id,
            views: doc.data()?.views || 0,
          });
        });
        dispatch(getPublicationsCounterSuccess(counterArray));
      });
    } catch (error) {
      dispatch(getPublicationsCounterError());
    }
  };
};

export const get_campaigns = (spaceid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const spaceRef = doc(db, pathToSpaceWithId(spaceid));
      const docSnap = await getDoc(spaceRef);
      const campaingRef = collection(db, pathToSpaceCampaigns(spaceid));
      const snap = query(campaingRef, orderBy("created", "desc"), limit(200));
      onSnapshot(snap, (querySnapshot) => {
        let campaignsArr: any = [];
        querySnapshot.forEach((doc) => {
          campaignsArr.push({
            id: doc.id,
            ...doc.data(),
            publication_status: getPublicationStatus(
              doc.data().publish_start_date,
              doc.data().publish_end_date,
              doc.data().publish_now,
              docSnap.data()?.spaceTimeZone
            ),
          });
        });
        dispatch(getCampaignsSuccess(campaignsArr));
      });
    } catch (error) {
      dispatch(getCampaignsError());
    }
  };
};

export const delete_user_campaings = (
  uid: string,
  spaceid: string,
  campaignid: string
) => {
  return async (dispatch: Dispatch, getState: any) => {
    dispatch(startLoading());
    const dbRef = doc(db, pathToSpaceCampaigns(spaceid), campaignid);
    const campaignsRef = doc(db, pathToCampaigns(), campaignid);
    try {
      await runTransaction(db, async (transaction) => {
        const doc = await transaction.get(dbRef);
        const campaignDoc = await transaction.get(campaignsRef);
        if (!doc.exists() && !campaignDoc.exists()) {
          return false;
        } else {
          transaction.delete(campaignsRef);
          transaction.delete(dbRef);
          dispatch(deleteCampaignsSuccess());
        }
      });
      // const deleteCallback = () => {
      //   console.log('delete!!')
      // }
      // deleteCampaignAllImages({ path: pathToCampaignStorage(uid, campaignid), callBack: deleteCallback })
    } catch (error) {
      console.error("For DELETE_USER_CAMPAINGS:", error);
    }
  };
};

const publicationStatus = (start_date: Date) => {
  const newTime = new Date().getTime();
  const publicationTime = new Date(start_date).getTime();
  let status = enumPublicationStatus.DRAFT;
  if (newTime < publicationTime) {
    status = enumPublicationStatus.PLANNED;
  } else if (newTime > publicationTime) {
    status = enumPublicationStatus.ACTIVE;
  } else {
    status = enumPublicationStatus.DRAFT;
  }
  return status;
};

export const create_new_campaign = (
  spaceid: string,
  data: any,
  callBack: (id: string) => void
) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const dbRef = doc(collection(db, pathToSpaceCampaigns(spaceid)));
      let short_id = sHash.encode(dbRef.id);
      const dataToSpace = {
        name: data.name,
        image: data.media_asset_image_story_format || null,
        type: data.type_choice.title,
        analytics_count_publications: 0,
        publication_status:
          data.publication_status || publicationStatus(data.publish_start_date),
        publish_now: data.publish_now || false,
        publish_start_date: data.publish_start_date,
        publish_end_date: data.publish_end_date,
        short_id,
        created: serverTimestamp(),
      };

      delete data.type_choice;
      const dataToCampaign = {
        ...data,
        short_id,
        publication_status:
          data.publication_status || publicationStatus(data.publish_start_date),
      };
      const objToCampaign = JSON.parse(
        JSON.stringify(dataToCampaign, function (k, v) {
          if (v === undefined) {
            return null;
          }
          return v;
        })
      );

      const createCallBack = async (logo: string | null) => {
        await setDoc(
          doc(db, pathToSpaceCampaigns(spaceid), short_id),
          dataToSpace
        );
        const docRef = doc(db, pathToSpaceCampaigns(spaceid), short_id);
        const isExist = await runTransaction(db, async (transaction) => {
          const doc = await transaction.get(docRef);
          if (!doc.exists()) {
            return false;
          } else {
            return true;
          }
        });
        if (isExist) {
          await setDoc(doc(db, pathToCampaigns(), short_id), {
            ...objToCampaign,
            created: serverTimestamp(),
            updated: serverTimestamp(),
          }).then(() => {
            dispatch(createCampaignSuccess());
            callBack(short_id);
          });
        }
      };

      createCallBack(null);
    } catch (error) {
      console.error("For CREATE_NEW_CAMPAINGS:", error);
      dispatch(getCampaignsError());
    }
  };
};

export const update_campaign = (
  spaceid: string,
  campaignid: string,
  data: any,
  callBack: () => void
) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const dbRef = doc(db, pathToSpaceCampaigns(spaceid), campaignid);
      const campaignsRef = doc(db, pathToCampaigns(), campaignid);

      const dataToSpace = {
        name: data.name,
        image: data.media_asset_image_story_format || null,
        type: data.type,
        analytics_count_publications: 0,
        publication_status:
          data.publication_status || publicationStatus(data.publish_start_date),
        publish_now: data.publish_now || false,
        publish_start_date: data.publish_start_date,
        publish_end_date: data.publish_end_date,
      };
      delete data.type_choice;
      const dataToCampaign = JSON.parse(
        JSON.stringify(data, function (k, v) {
          if (v === undefined) {
            return null;
          }
          return v;
        })
      );

      const updateCallBack = async (logo: string | null) => {
        try {
          const isTransactionSuccess = await runTransaction(
            db,
            async (transaction) => {
              const sfDoc = await transaction.get(dbRef);
              const spaceDoc = await transaction.get(campaignsRef);
              if (!sfDoc.exists() && !spaceDoc.exists()) {
                return false;
              } else {
                //update data to space_to_campaigns
                transaction.update(dbRef, dataToSpace);
                //update data to campaigns
                transaction.update(campaignsRef, {
                  ...dataToCampaign,
                  updated: serverTimestamp(),
                });
                return true;
              }
            }
          );

          if (isTransactionSuccess) {
            callBack();
          } else {
            throw "Error While Update";
          }
        } catch (e) {
          console.error("For UPDATE_SPACE:", e);
        }
      };

      updateCallBack(null);
    } catch (error) {
      dispatch(getCampaignsError());
      console.error("UPDATE_CAMPAINGS error:", error);
    }
  };
};

export const get_campaign_byid = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const campaignRef = doc(db, pathToCampaignWithId(id));
      const docSnap = await getDoc(campaignRef);
      dispatch(getCampaignSuccess({ id: docSnap.id, ...docSnap.data() }));
    } catch (error) {
      console.error("error:", error);
      dispatch(getCampaignsError());
    }
  };
};

export const reset_campaign = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getCampaignSuccess(null));
  };
};

export const get_data_space_by_id = (space_id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const spaceRef = doc(db, pathToSpaceWithId(space_id));
      const docSnap = await getDoc(spaceRef);
      dispatch(getSpaceByIdSuccess(docSnap.data()));
    } catch (error) {
      dispatch(getSpacesByIdError());
    }
  };
};

export const get_lottery_participants_for_campaign = (campaignid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const lotteryParticipantsForCampaignRef = collection(
        db,
        pathToLotteryParticipantsForCampaign(campaignid)
      );
      const first = query(lotteryParticipantsForCampaignRef, limit(200));
      onSnapshot(first, (querySnapshot) => {
        let lotteryParticipantsForCampaignArr: ILotteryParticipantsForCampaignType[] =
          [];
        querySnapshot.forEach((doc) => {
          lotteryParticipantsForCampaignArr.push({
            id: doc.id,
            name: doc.data().name,
            email: doc.data().email,
            entered_data: doc.data().entered_data,
            newsletter_accepted: doc.data().newsletter_accepted || false,
            shared_post_step_1: doc.data().shared_post_step_1 || false,
            shared_post_step_2: doc.data().shared_post_step_2 || false,
            checkbox1: doc.data().checkbox1 || false,
            // created: doc.data().created,
            // updated: doc.data().update,
          });
        });
        dispatch(
          getLotteryParticipantsForCampaignSuccess(
            lotteryParticipantsForCampaignArr
          )
        );
      });
    } catch (error) {
      dispatch(getLotteryParticipantsForCampaignError());
    }
  };
};

export const get_counter_campaign = (campaignid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const counterCampaignRef = doc(db, pathToCounterForCampaign(campaignid));
      onSnapshot(counterCampaignRef, (querySnapshot) => {
        dispatch(getCounterCampaignSuccess(querySnapshot.data()));
      });
    } catch (error) {
      dispatch(getCounterCampaignError());
    }
  };
};

export const add_winner = (campaignid: string, data: any) => {
  return async (dispatch: Dispatch) => {
    dispatch(startWinnerLoading());
    try {
      const dbRef = doc(db, pathToWinner(campaignid));
      await setDoc(
        dbRef,
        {
          winner: data,
        },
        { merge: true }
      );
      dispatch(addWinner());
    } catch (error) {
      console.error("For WIINER:", error);
      dispatch(addWinner());
    }
  };
};

export const get_winner_user = (campaignid: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const counterCampaignRef = doc(db, pathToWinner(campaignid));
      onSnapshot(counterCampaignRef, (querySnapshot) => {
        dispatch(getWinnerUserSuccess(querySnapshot.data()?.winner));
      });
    } catch (error) {
      dispatch(getWinnerUserError());
    }
  };
};

export const set_adConfing_data = (data: any, hasVideo: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(setAdConfingData({ adConfing: data, hasVideo: hasVideo }));
  };
};

export const store_participants_id = (id: string) => {
  return (dispatch: Dispatch) => {
    dispatch(setParticipantsid(id));
  };
};
