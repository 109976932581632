import { Button, Fab, Stack, Typography } from "@mui/material";
import { Fragment } from "react";
import Iconify from "src/components/iconify/Iconify";
import { useSelector } from "src/redux/store";
import ImageSlider from "./ImageSlider";
import { isIOS } from "react-device-detect";

import Clipboard from "react-clipboard.js";

interface Props {
  onFormatSelect: () => void;
}

const chipStyle = {
  backgroundColor: `#fff`,
  padding: `2px`,
  marginRight: "10px",
};

function FormatChoice({ onFormatSelect }: Props) {
  const { isLoading, sharedImage } = useSelector((state) => state.fanpage);
  return (
    <Fragment>
      <Stack
        sx={{ marginTop: "40px" }}
        justifyContent="space-between"
        alignItems="center"
        width={window.innerWidth}
        height="100%"
      >
        <Typography sx={{}}>Auswahl Format</Typography>
        <ImageSlider images={[{ src: sharedImage || "" }]} />
        <Typography>Storyformat</Typography>

        <Stack
          justifyContent="space-around"
          textAlign="center"
          align-items="center"
          width="95%"
        >
          <Typography sx={{ my: 1 }}>Optimal nutzbar für:</Typography>
          <Stack
            direction="row"
            justifyContent="center"
            padding="5px"
            width="100%"
            sx={{
              overflowX: "scroll",
              "&::-webkit-scrollbar": {
                height: 0,
              },
            }}
            display="grid"
            gridTemplateColumns="repeat(4, 0fr)"
            gap={2}
            onClick={onFormatSelect}
          >
            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="skill-icons:instagram" />
            </Fab>
            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="logos:facebook" />
            </Fab>
            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="logos:tiktok-icon" />
            </Fab>
            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="logos:whatsapp-icon" />
            </Fab>

            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="logos:telegram" />
            </Fab>
            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="logos:twitter" />
            </Fab>
            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="uil:snapchat-ghost" />
            </Fab>
            <Fab style={chipStyle} size="small" variant="soft" color="info">
              <Iconify icon="mingcute:more-1-line" />
            </Fab>
          </Stack>
        </Stack>

        <Stack
          sx={{
            width: `90%`,
            my: 2,
          }}
        >
          <Button
            fullWidth
            variant="contained"
            onClick={onFormatSelect}
            disabled={isLoading}
            sx={{
              textTransform: "none",
            }}
          >
            {isIOS ? "Jetzt teilen" : "Jetzt teilen"}
          </Button>
        </Stack>
      </Stack>
    </Fragment>
  );
}

export default FormatChoice;
