import { createSlice, Dispatch } from "@reduxjs/toolkit";
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import { enumPublicationStatus } from "src/@types/campaigns";
import { db } from "src/firebase";
import { getPublicationStatus } from "src/utils/getPublicationStatus";

const initialState = {
  isLoading: false,
  campaignBySlug: null,
};

const findCampaignBySlug = createSlice({
  name: "findCampaignBySlug",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // find slug
    getFindByCampaignIdSuccess(state, action) {
      state.campaignBySlug = action.payload;
      state.isLoading = false;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    getFindByCampaignIdError(state) {
      state.isLoading = false;
    },
  },
});

export default findCampaignBySlug.reducer;
const {
  startLoading,
  getFindByCampaignIdSuccess,
  getFindByCampaignIdError,
  stopLoading,
} = findCampaignBySlug.actions;

const pathToSpaceCampaigns = (id: string) => {
  return `spaces/${id}/space_to_campaigns`;
};

const partToSpace = () => {
  return `spaces/`;
};

export const find_campaign_by_slug = (slug: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(startLoading());
    try {
      const getSpaceRef = collection(db, partToSpace());
      const snapShort = query(getSpaceRef, limit(200));
      onSnapshot(snapShort, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (
            doc.data()?.slug &&
            doc.data()?.slug.toLocaleLowerCase() === slug.toLocaleLowerCase()
          ) {
            const getCampaignRef = collection(db, pathToSpaceCampaigns(doc.id));
            const campaignShort = query(
              getCampaignRef,
              orderBy("created", "desc"),
              limit(200)
            );
            onSnapshot(campaignShort, (queryCampaignSnapshot) => {
              let isActive;
              let campaignIdArr: string[] = [];

              console.log(123);

              queryCampaignSnapshot.forEach((d) => {
                isActive = getPublicationStatus(
                  d.data().publish_start_date,
                  d.data().publish_end_date,
                  d.data().publish_now,
                  doc.data()?.spaceTimeZone
                );
                console.log(isActive);
                if (isActive === enumPublicationStatus.ACTIVE) {
                  campaignIdArr.push(d.id);
                }
              });
              if (campaignIdArr && campaignIdArr.length > 0) {
                dispatch(getFindByCampaignIdSuccess(campaignIdArr));
              }
            });
          }
        });
        dispatch(stopLoading());
      });
    } catch (error) {
      dispatch(getFindByCampaignIdError());
    }
  };
};
