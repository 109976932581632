// ----------------------------------------------------------------------

import { UserListPage } from "./elements";

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  space: path(ROOTS_DASHBOARD, "/space"),
  createspace: path(ROOTS_DASHBOARD, "/space/create"),
  UserListPage: path(ROOTS_DASHBOARD, "/UserListPage"),
};
