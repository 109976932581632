export const drawingConfig = [
  {
    comment: "Title 1: Die Recken - Tippspiel",
    id: null,
    type: "text",
    text: "RECKEN TIPPSPIEL",
    x: null,
    y: 300,
    position: "center",
    max_width_in_px: 800,
    color: "#ffffff",
    for: null,
    font: "/assets/fonts/dirty_headline/DirtyHeadline.ttf",
  },
  {
    comment:
      "Title (Mein Tipp:). Will be always there. id == null => will not be matched to campaign settings",
    id: null,
    type: "text",
    text: "Mein Tipp:",
    x: null,
    y: 450,
    position: "center",
    max_width_in_px: 550,
    color: "#ffffff",
    for: null,
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
  {
    comment: "text vs between logos",
    id: null,
    type: "text",
    text: "VS",
    x: 350,
    y: 700,
    position: "center",
    max_width_in_px: 150,
    for: null,
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
  {
    for: "campaign_content_3",
    comment: "left logo",
    id: "id_title_2 (left Logo)",
    type: "image",
    url: "",
    x: 100,
    y: 520,
    position: null,
    width: 350,
    height: 350,
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
  {
    for: "campaign_content_4",
    comment: "right logo",
    id: "id_title_2",
    type: "image",
    url: "",
    x: 630,
    y: 520,
    position: null,
    width: 350,
    height: 350,
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
  {
    for: null,
    comment: "block do draw the tipped result",
    id: "tipping",
    type: "tipping_game_result",
    x: 350,
    y: 910,
    max_height_in_px: 250,
    max_width_in_px: 800,
    value_left: 12,
    value_right: 30,
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
  {
    for: "campaign_content_5",
    comment: "Bottom text above white 'button'",
    id: "",
    type: "text",
    text: "Gewinne 2x2 VIP Karten\nfür ein Heimspiel der Recken!",
    x: null,
    y: 1230,
    position: "center",
    max_width_in_px: 800,
    color: "#ffffff",
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
  {
    for: "campaign_content_6",
    comment: "white button, which will given as an image during the config",
    id: "",
    type: "image",
    text: null,
    x: 290,
    y: 1600,
    width: 500,
    height: 100,
    url: "",
    position: null,
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
  {
    for: "campaign_content_7",
    comment: "Bottom text below white 'button'",
    id: "",
    type: "text",
    text: "Für die Teilnahme am Gewinnspiel\nbesuche unser Instagram Profil",
    x: null,
    y: 1360,
    position: "center",
    max_width_in_px: 800,
    color: "#ffffff",
    font: "/assets/fonts/akzidenz_grotesk/AkzidenzGrotesk-Cond.otf",
  },
];
