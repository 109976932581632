import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "src/firebase";

interface IUploadFileProps {
  file: File;
  path: string;
  callBack: (url: string) => void;
  percentCallBack?: (percent: number) => void;
}

interface IDeleteFileProps {
  path: string;
  callBack: () => void;
  percentCallBack?: (percent: number) => void;
}

const pathToUploadSpaceLogo = (path: string, name: string) => {
  return `/files/${path}/${name}`;
};

export const uploadSpaceLogo = ({ file, path, callBack }: IUploadFileProps) => {
  const fullPath = pathToUploadSpaceLogo(path, "spaceLogo.png");
  handleUpload({ file, path: fullPath, callBack });
};

export const deleteSpaceLogo = ({ path, callBack }: IDeleteFileProps) => {
  const fullPath = pathToUploadSpaceLogo(path, "spaceLogo.png");
  handleDelete({ path: fullPath, callBack });
};

export const uploadSpaceSocialImage = ({
  file,
  path,
  callBack,
}: IUploadFileProps) => {
  handleUpload({ file, path: path, callBack });
};
export const deleteSpaceSocialImage = ({
  path,
  callBack,
}: IDeleteFileProps) => {
  handleDelete({ path, callBack });
};
export const uploadOwnFont = ({ file, path, callBack }: IUploadFileProps) => {
  handleUpload({ file, path: path, callBack });
};
export const deleteOwnFont = ({ path, callBack }: IDeleteFileProps) => {
  handleDelete({ path, callBack });
};
export const deleteCampaignImage = ({ path, callBack }: IDeleteFileProps) => {
  handleDelete({ path, callBack });
};

export const deleteCampaignAllImages = ({
  path,
  callBack,
}: IDeleteFileProps) => {
  handleDelete({ path, callBack });
};

const handleDelete = ({ path, callBack }: IDeleteFileProps) => {
  const desertRef = ref(storage, path);
  deleteObject(desertRef)
    .then(() => {
      callBack();
    })
    .catch((error) => {
      console.error("For DELETEING FILE:", error);
      callBack();
    });
};

export const uploadCampaignFile = ({
  file,
  path,
  callBack,
  percentCallBack,
}: IUploadFileProps) => {
  handleUpload({ file, path, callBack, percentCallBack });
};

const handleUpload = ({
  file,
  path,
  callBack,
  percentCallBack,
}: IUploadFileProps) => {
  if (file) {
    const storageRef = ref(storage, path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(percent);
        if (percentCallBack) {
          percentCallBack(percent);
        }
      },
      (err) => console.error("while image upload:", err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          callBack(url);
        });
      }
    );
  }
};
